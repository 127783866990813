import styles from "./calendar.module.css";
import { useState } from "react";
import { workdata } from "../../utils/data";
const dayjs = require("dayjs");

type Tmonths = {
  name: string;
  nom: number;
  mm: string;
};

type Tday = {
  day: number;
  weekday: number;
  workday: number;
};

const months = [
  { name: "январь", nom: 1, mm: "января" },
  { name: "февраль", nom: 2, mm: "февраля" },
  { name: "март", nom: 3, mm: "марта" },
  { name: "апрель", nom: 4, mm: "апреля" },
  { name: "май", nom: 5, mm: "мая" },
  { name: "июнь", nom: 6, mm: "июня" },
  { name: "июль", nom: 7, mm: "июля" },
  { name: "август", nom: 8, mm: "августа" },
  { name: "сентябрь", nom: 9, mm: "сентября" },
  { name: "октябрь", nom: 10, mm: "октября" },
  { name: "ноябрь", nom: 11, mm: "ноября" },
  { name: "декабрь", nom: 12, mm: "декабря" },
];

const weeks = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];

const setday = (month: number, year: number) => {
  const monthstart = dayjs(`${month + 1}.01.${year}`).startOf("month");
  const monthend = dayjs(`${month + 1}.01.${year}`).endOf("month");
  const days: number = monthend.diff(monthstart, "day") + 1;

  const listmonth: Tday[] = [];

  let i = 0;
  if (monthstart.$W === 0) {
    i = 6;
  } else {
    i = monthstart.$W - 1;
  }

  while (i > 0) {
    const list: Tday = {
      day: 0,
      weekday: 0,
      workday: 1,
    };

    listmonth.push(list);
    i = i - 1;
  }

  i = 0;
  while (i < days) {
    const date2 = monthstart.add(i, "day");
    let available = 0;

    available = workdata.filter(
      (workdata) => workdata.day === date2.$D && workdata.month === date2.$M + 1
    ).length;

    if (date2 < dayjs()) {
      available = 1;
    }

    const dd: number = date2.$D;
    const wd: number = date2.$W;

    const list: Tday = {
      day: dd,
      weekday: wd,
      workday: available,
    };

    listmonth.push(list);
    i = i + 1;
  }

  i = 0;
  if (monthend.$W === 0) {
    i = 0;
  } else {
    i = 7 - monthend.$W;
  }

  while (i > 0) {
    const list: Tday = {
      day: 0,
      weekday: 0,
      workday: 1,
    };

    listmonth.push(list);
    i = i - 1;
  }

  return listmonth;
};

type TCalendar = {
  func: React.Dispatch<React.SetStateAction<string>>;
};

export const Calendar = (props: TCalendar) => {
  const setdata = props.func;
  const [month, setmonth] = useState<Tmonths>(months[dayjs().month()]);
  const [god, setgod] = useState<number>(dayjs().year());
  const [dayorder, setDayorder] = useState(0);
  const [listday, setListday] = useState(
    setday(dayjs().month(), dayjs().year())
  );

  const [left, setleft] = useState(false);

  const onClick = () => {
    setmonth(months[month.nom]);
    let yy = dayjs().year();

    if (month.nom === 12) {
      yy = dayjs().year() + 1;
      setmonth(months[0]);
      setListday(setday(0, yy));
      setgod(god + 1);
    } else {
      setListday(setday(month.nom, god));
    }
    setleft(true);
  };

  const onClickl = () => {
    setmonth(months[month.nom - 2]);

    let yy = dayjs().year();

    if (month.nom === 1) {
      yy = god - 1;
      setmonth(months[11]);
      setListday(setday(11, yy));
      setgod(god - 1);
    } else {
      setListday(setday(month.nom - 2, god));
    }

    if (month.nom === dayjs().month() + 2) {
      setleft(false);
    }
  };
  const onClicklorder = (item: number) => {
    setDayorder(item);
    setdata(`${item} 
    ${month.mm} 
    ${god}`);
  };

  return (
    <div className={styles.main}>
      <div>
        <div className={styles.mms}>
          {left && <div className={styles.text1} onClick={onClickl}>{`<`}</div>}
          {!left && (
            <div className={styles.text1} onClick={onClickl}>
              {""}
            </div>
          )}
          <div className={styles.text}>{month.name}</div>
          <div className={styles.text1} onClick={onClick}>{`>`}</div>
        </div>
        <div className={styles.calendar}>
          <div className={styles.month}>
            {weeks.map((item, index) => {
              return (
                <div className={`${styles.day} ${styles.dayt}`} key={index}>
                  {item}
                </div>
              );
            })}

            {listday.map((item, index) => {
              if (item.workday === 1) {
                return <div className={`${styles.dayoff}`} key={index}></div>;
              } else {
                if (item.day === dayorder) {
                  return (
                    <div
                      className={`${styles.day} ${styles.dayorder}`}
                      key={index}
                      onClick={() => onClicklorder(item.day)}
                    >
                      {item.day}
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={styles.day}
                      key={index}
                      onClick={() => onClicklorder(item.day)}
                    >
                      {item.day}
                    </div>
                  );
                }
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Calendar;
