import styles from "./trabalha.module.css";
import { dataCall } from "../../utils/data";
import iconfon from "../../images/iconfon.jpg";
import Cardсall from "../../components/cardсall/cardсall";
import None from "../../components/none/none";
import Contact from "../../components/contact/contact";

function Trabalha() {
  return (
    <div className={styles.main}>
      <h2 style={{ paddingLeft: 20, paddingRight: 20 }}>
        Вы живете в Порту или поблизости и хотите работать?
      </h2>
      <h2 style={{ paddingLeft: 20, paddingRight: 20 }}>
        Присоединяйтесь к нашей команде
      </h2>

      <Contact work={true}></Contact>
      <None hh={50}></None>
    </div>
  );
}

export default Trabalha;
/*useEffect(() => {}, []);
  const [isactiv, setIsactiv] = useState(0);*/
