import { NavLink } from "react-router-dom";
import styles from "./buttonordbox.module.css";
import icon from "../../images/logo_1.png";
import { AnimatePresence, motion } from "framer-motion";
import { useResize } from "../../utils/hook";
import { useEffect } from "react";
import ButtonOrd from "../buttonord/buttonord";

type Tprops = {
  top: number;
  left: number;
  bottom: number;
  right: number;
  scale: number;
};

export function ButtonOrdBox() {
  return (
    <motion.div
      className={styles.mainpart}
      initial="hidden"
      whileInView="visible"
      variants={{
        hidden: {
          clipPath: "inset(50% 50% 50% 50% round 100px)",
          transition: {
            type: "spring",
            bounce: 0,
            duration: 0.3,
          },
        },
        visible: (custom: number) => ({
          clipPath: "inset(0% 0% 0% 0% round 100px)",
          transition: {
            type: "spring",
            bounce: 0,
            duration: 0.7,
            delayChildren: 0.3,
            staggerChildren: 0.05,
            delay: 2,
          },
        }),
      }}
    >
      <ButtonOrd></ButtonOrd>
    </motion.div>
  );
}
export default ButtonOrdBox;
