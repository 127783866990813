import styles from "./cleaningnew.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { useResize } from "../../utils/hook";
import Cardclean from "../cardclean/cardclean";
import { Tlist } from "../../utils/data";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type Tprops = {
  name: string;
  list: Tlist;
};

export const Cleaningnew = ({ name, list }: Tprops) => {
  const wl = useResize().width;
  const [col, setCol] = useState(1);
  const [widthd, setwidthd] = useState({ width: `1100px` });
  const [ddop, setddop] = useState(false);
  const [ord, setord] = useState(false);
  const [avto, setAvto] = useState(true);

  useEffect(() => {
    if (name === "Дополнительные услуги") {
      setddop(true);
    }
    if (
      name === "Какую уборку хотите заказать?" ||
      name === "Только дополнительные услуги?"
    ) {
      setord(true);
      setAvto(false);
    }
  }, []);

  useEffect(() => {
    if (wl > 1500) {
      setCol(4);
      setwidthd({ width: `1350px` });
    } else {
      if (wl > 1200) {
        setCol(3);
        setwidthd({ width: `1000px` });
      } else {
        if (wl > 800) {
          setCol(2);
          setwidthd({ width: `680px` });
        } else {
          setCol(1);
          setwidthd({ width: `100%` });
        }
      }
    }
  }, [wl]);

  const settings = {
    infinite: true,
    slidesToShow: col,
    slidesToScroll: 1,
    autoplay: avto,
    speed: 600,
    autoplaySpeed: 4000,
    cssEase: "linear",
    swipeToSlide: true,
    dots: true,
    focusOnSelect: !ddop,
  };

  return (
    <div className={styles.main}>
      <h2 style={{ paddingLeft: 20, paddingRight: 20 }}>{name}</h2>
      <div className={styles.stringtext} style={widthd}>
        <Slider {...settings}>
          {list.map((item, index) => {
            return (
              <div className={styles.box} key={index}>
                <div className={styles.boxnew} key={index}>
                  <Cardclean
                    key={index}
                    name={item.name}
                    list={item.list}
                    image={item.image}
                    link={item.link}
                    dop={ddop}
                    ord={ord}
                    price={item.price}
                    id={item.id}
                  ></Cardclean>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Cleaningnew;
