import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  listdopbase,
  Tlistdop,
  TtypeClean,
  typeCleanbase,
} from "../utils/data";

export type Torder = {
  email: string;
  name: string;
  tel: string;
  data: string;
  time: string;
  room: number;
  wc: number;
  typew: TtypeClean;
  workd: Tlistdop[];
};

export const initialState: Torder = {
  email: "",
  name: "",
  tel: "",
  data: "",
  time: "",
  room: 1,
  wc: 1,
  typew: typeCleanbase,
  workd: [listdopbase],
};

export const orderSlice = createSlice({
  name: "orderst",
  initialState: initialState,
  reducers: {
    setorder: (state, action: PayloadAction<Torder>) => {
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.tel = action.payload.tel;
      state.data = action.payload.data;
      state.time = action.payload.time;
      state.room = action.payload.room;
      state.wc = action.payload.wc;
      state.typew = action.payload.typew;
      state.workd = action.payload.workd;
    },
    addworkd: (state, action: PayloadAction<Tlistdop>) => {
      const dop = action.payload;
      state.workd.push(dop);
    },
    delworkd: (state, action: PayloadAction<number>) => {
      state.workd.splice(action.payload, 1);
    },
    setdata: (state, action: PayloadAction<string>) => {
      state.data = action.payload;
    },
    setname: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    settel: (state, action: PayloadAction<string>) => {
      state.tel = action.payload;
    },
    settime: (state, action: PayloadAction<string>) => {
      state.time = action.payload;
    },
    setroom: (state, action: PayloadAction<number>) => {
      state.room = action.payload;
    },
    setwc: (state, action: PayloadAction<number>) => {
      state.wc = action.payload;
    },
    settypew: (state, action: PayloadAction<TtypeClean>) => {
      state.typew = action.payload;
    },
  },
});

export default orderSlice.reducer;
export const {
  setorder,
  addworkd,
  delworkd,
  setdata,
  setname,
  settel,
  settime,
  setroom,
  setwc,
  settypew,
} = orderSlice.actions;
