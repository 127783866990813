import { motion, MotionValue, useTransform } from "framer-motion";
import styles from "./app-header.module.css";
import { NavLink, useLocation } from "react-router-dom";
import MenuLeft from "../menuleft/menuleft";
import { useResize } from "../../utils/hook";
import { useEffect, useState } from "react";
import Flat from "../flat/flat";
import LogoMain from "../logomain/logomain";
import imageflat from "../../images/imageflat.png";
import imageflatsfrom from "../../images/image098min.png";

type Tprops = {
  wlh: number;
  wlw: number;
  wl: boolean;
  scrollY: MotionValue<number>;
  offsetY: number[];
};

const textAnimationleft = {
  hidden: {
    opacity: 0,
  },
  visible: () => ({
    opacity: 1,
    transition: { delay: 1, duration: 0.8 },
  }),
};

const textAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: () => ({
    opacity: 1,
    transition: { delay: 0.5, duration: 0.8 },
  }),
};

const scaleRes = (wlw: number) => {
  let res = [wlw / 500, 0.7];

  if (wlw < 1200) {
    res = [wlw / 400, 0.7];
  }
  if (wlw < 950) {
    res = [wlw / 350, 0.7];
  }
  if (wlw < 830) {
    res = [wlw / 330, 0.7];
  }

  return res;
};

const leftRes = (wlw: number) => {
  let res = [wlw / 2.5, -20];
  if (wlw < 1200) {
    res = [wlw / 3, -20];
  }
  if (wlw < 830) {
    res = [wlw / 3.2, -25];
  }
  if (wlw < 700) {
    res = [wlw / 3.5, -25];
  }
  if (wlw < 600) {
    res = [wlw / 4, -25];
  }
  if (wlw < 550) {
    res = [wlw / 5, -30];
  }
  if (wlw < 450) {
    res = [wlw / 7, -30];
  }
  if (wlw < 400) {
    res = [wlw / 15, -30];
  }
  return res;
};

//export function AppHeader({ wlh, wlw, wl, scrollY, offsetY }: Tprops) {
export function AppHeader({ scrollY, wlh, wlw, wl, offsetY }: Tprops) {
  const scaleSize = scaleRes(wlw);
  const height = useTransform(scrollY, offsetY, [wlh, 65]);
  const scale = useTransform(scrollY, offsetY, scaleSize);
  const color = useTransform(scrollY, offsetY, [
    "rgba(200, 243, 239, 0.363)",
    "rgba(200, 243, 239, 1)",
  ]);

  const top = useTransform(scrollY, offsetY, [wlh / 2.5, -10]);
  const right = useTransform(scrollY, offsetY, leftRes(wlw));
  const [images, setImages] = useState<string>(imageflat);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ ]);

  useEffect(() => {
    if (wl) {
      setImages(imageflat);
    } else {
      setImages(imageflatsfrom);
    }
  }, [wl]);

  return (
    <motion.div
      className={styles.main}
      initial="hidden"
      whileInView="visible"
      style={{ height, backgroundColor: color }}
    >
      <motion.div
        className={styles.logo}
        style={{
          scale,
          top,
          right,
        }}
      >
        <LogoMain></LogoMain>
      </motion.div>
    </motion.div>
  );
}
export default AppHeader;
/*<motion.div className={styles.link} style={{ opacity }}>
        <NavLink to="/order" className={styles.linktext}>
          ORDER NOW
        </NavLink>
      </motion.div>*/
/*return (
        <motion.div
          className={styles.main}
          initial="hidden"
          whileInView="visible"
          style={{ height, backgroundImage: `url(${images})` }}
        >
          <motion.div
            className={styles.logobox}
            variants={textAnimation}
            style={{ backgroundColor: color }}
          >
            <motion.div
              className={styles.logo}
              style={{
                scale,
                top,
                right,
              }}
            >
              <LogoMain></LogoMain>
            </motion.div>
          </motion.div>
        </motion.div>
      );*/
