import { useEffect, useState } from "react";
import styles from "./cardсall.module.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import IconLink from "../iconlink/iconlink";
import Formcallnew from "../formcallnew/formcallnew";

type Tprops = {
  name: string;
  actionType: string;
  icon: string;
  iconw: string;
  link: string;
  action: string;
};

const Cardсall = ({ name, actionType, icon, iconw, link, action }: Tprops) => {
  const [front, setFront] = useState(false);

  useEffect(() => {
    if (name === "Заказать звонок на сайте, можно тут") {
      setFront(true);
    }
  }, []);

  return (
    <motion.div className={styles.mainbox}>
      <div className={styles.front}>
        <div className={styles.card}>
          <div className={styles.name}>{name}</div>
          <IconLink
            homeone={icon}
            hometwo={icon}
            rad={120}
            action={"false"}
          ></IconLink>
          <div className={styles.text}>{actionType}</div>
        </div>
      </div>

      <div className={styles.back}>
        {front && <Formcallnew></Formcallnew>}
        {!front && (
          <NavLink className={styles.cardf} to={link}>
            <div className={styles.name}>{action}</div>
            <IconLink
              homeone={iconw}
              hometwo={iconw}
              rad={120}
              action={action}
            ></IconLink>
            <div className={styles.text}>{actionType}</div>
          </NavLink>
        )}
      </div>
    </motion.div>
  );
};

export default Cardсall;
/*whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}*/
