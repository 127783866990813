import styles from "./adviceone.module.css";

import iconfon from "../../images/iconfon.jpg";

import { texta } from "../../utils/text";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Adviceone = () => {
  const param = Number(useParams().name);
  let text;

  text = texta.find((el) => el.id === param);
  if (!text) {
    text = texta[0];
  }
  const content = ["уборка", "Порто", "Португалия", "Porto", "Cleaning"];
  text.content.map((item, index) => {
    content.push(item.name);
  });

  return (
    <div className={styles.main}>
      <Helmet>
        <title>{text.name} | Полезные советы по уборке</title>
        <meta name="description" content={text.name} />
        <meta name="keywords" content={content.join(", ")} />
        <link
          rel="canonical"
          href={`https://www.portocleaning.com/advice/${text.id}`}
        />
      </Helmet>

      <h2 style={{ paddingLeft: 20, paddingRight: 20 }}>{text.name}</h2>
      <div className={styles.typeClining}>
        {text.content.map((item, index) => {
          return (
            <div className={styles.item} key={index}>
              <h2 style={{ paddingLeft: 20, paddingRight: 20 }}>{item.name}</h2>
              <p className={styles.textp}>{item.text}</p>
              <img className={styles.image} src={item.image} alt="pictura" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Adviceone;
//useParams
