import React, { useEffect, useState } from "react";
import styles from "./formacontact.module.css";
import wa from "../../images/wa.png";
import waaction from "../../images/waactive.png";
import tel from "../../images/tel.png";
import telactive from "../../images/telactive.png";
import tg from "../../images/telegram.png";
import tgaction from "../../images/telegramactive.png";
import IconColor from "../iconcolor/iconcolor";
import { InputMask } from "@react-input/mask";

type Tcontact = {
  func: React.Dispatch<React.SetStateAction<string>>;
  funcok: React.Dispatch<React.SetStateAction<boolean>>;
};

const FormaContact = (props: Tcontact) => {
  const setdata = props.func;
  const setok = props.funcok;
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [telegram, settelegram] = useState("");
  const [whatsapp, setwhatsapp] = useState("");
  const [email, setEmail] = useState("");
  const [phoneaction, setPhoneaction] = useState(false);
  const [telegramaction, settelegramaction] = useState(false);
  const [whatsappaction, setwhatsappaction] = useState(false);

  const changeName = (e: any) => {
    setName(e.target.value);
    setdata(
      `имя:${name}, телефон:${phone}, телеграм:${telegram}, whatsapp:${whatsapp}, почта:${email}`
    );
  };
  const changePhone = (e: any) => {
    setPhone(e.target.value);
    setdata(
      `имя:${name}, телефон:${phone}, телеграм:${telegram}, whatsapp:${whatsapp}, почта:${email}`
    );

    if (e.target.value !== "") {
      setPhoneaction(true);
      setok(true);
    } else {
      setPhoneaction(false);
    }
  };

  const changeTelegram = (e: any) => {
    settelegram(e.target.value);
    setdata(
      `имя:${name}, телефон:${phone}, телеграм:${telegram}, whatsapp:${whatsapp}, почта:${email}`
    );
    if (e.target.value !== "") {
      settelegramaction(true);
      setok(true);
    } else {
      settelegramaction(false);
    }
  };

  const changemail = (e: any) => {
    setEmail(e.target.value);
    setdata(
      `имя:${name}, телефон:${phone}, телеграм:${telegram}, whatsapp:${whatsapp}, почта:${email}`
    );
    setok(true);
  };
  const changewhatsapp = (e: any) => {
    setwhatsapp(e.target.value);
    setdata(
      `имя:${name}, телефон:${phone}, телеграм:${telegram}, whatsapp:${whatsapp}, почта:${email}`
    );
    if (e.target.value !== "") {
      setwhatsappaction(true);
      setok(true);
    } else {
      setwhatsappaction(false);
    }
  };

  return (
    <div className={styles.main}>
      <h3 className={styles.text}>Ваше имя</h3>
      <input
        className={styles.userBox}
        type="text"
        value={name}
        name="name"
        onChange={changeName}
        placeholder="Имя"
      />

      <h3 className={styles.text}>E-mail</h3>
      <input
        className={styles.userBox}
        type="text"
        value={email}
        name="email"
        onChange={changemail}
        placeholder="Почта"
      />

      <div className={styles.box}>
        <IconColor
          homeone={tel}
          hometwo={telactive}
          rad={40}
          action={phoneaction}
        ></IconColor>
        <h3 className={styles.text}>Телефон</h3>
      </div>
      <InputMask
        className={styles.userBox}
        type="text"
        value={phone}
        name="phone"
        mask="+351 ___ ___ ___"
        replacement={{ _: /\d/ }}
        onChange={changePhone}
        placeholder="Телефон"
      />
      <div className={styles.box}>
        <IconColor
          homeone={wa}
          hometwo={waaction}
          rad={40}
          action={whatsappaction}
        ></IconColor>
        <h3 className={styles.text}>WhatsApp</h3>
      </div>
      <input
        className={styles.userBox}
        type="text"
        value={whatsapp}
        name="whatsapp"
        onChange={changewhatsapp}
        placeholder="whatsApp"
      />
      <div className={styles.box}>
        <IconColor
          homeone={tg}
          hometwo={tgaction}
          rad={40}
          action={telegramaction}
        ></IconColor>
        <h3 className={styles.text}>Telegram</h3>
      </div>
      <InputMask
        mask="@______________________________________"
        replacement={{ _: /./ }}
        className={styles.userBox}
        type="text"
        value={telegram}
        name="telegram"
        onChange={changeTelegram}
        placeholder="Telegram"
      />

      <h4 className={styles.text}>Укажите любой удобный способ связи</h4>
    </div>
  );
};

export default FormaContact;
