import styles from "./cardcompany.module.css";
import { motion } from "framer-motion";

import IconLink from "../iconlink/iconlink";

type Tprops = {
  name: string;
  actionType: string;
  icon: string;
  iconw: string;
};

const Cardcompany = (props: Tprops) => {
  const homeone = props.icon;
  const hometwo = props.iconw;

  return (
    <motion.div
      className={`${styles.main}`}
      whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
    >
      <div className={styles.name}>{props.name}</div>
      <IconLink
        homeone={hometwo}
        hometwo={homeone}
        rad={100}
        action={"false"}
      ></IconLink>
      <div className={styles.text}>{props.actionType}</div>
    </motion.div>
  );
};

export default Cardcompany;
