import styles from "./status.module.css";
import { NavLink } from "react-router-dom";

type TOrderypeprops = {
  nameone: string;
  linkone: string;
  nametwo: string;
  namethree: string;
};

function Status(props: TOrderypeprops) {
  return (
    <div className={styles.main}>
      <div className={styles.all}>
        <NavLink
          to={props.linkone}
          className={styles.box}
          style={{ justifyContent: "flex-end" }}
        >
          {props.nameone}
        </NavLink>
        <div className={styles.triangleright}></div>
      </div>
      <div className={styles.all}>
        <div className={styles.box} style={{ justifyContent: "center" }}>
          {props.nametwo}
        </div>

        <div className={styles.triangleright}></div>
      </div>
      <div className={styles.all}>
        <div className={styles.box}>{props.namethree}</div>
        <div className={styles.triangleright}></div>
      </div>
    </div>
  );
}

export default Status;
