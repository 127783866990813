import { motion, MotionValue, useTransform } from "framer-motion";
import styles from "./app-headersmall.module.css";
import { NavLink, useLocation } from "react-router-dom";
import MenuLeft from "../menuleft/menuleft";
import { useResize } from "../../utils/hook";
import { useEffect, useState } from "react";
import Flat from "../flat/flat";
import LogoMain from "../logomain/logomain";
import imageflat from "../../images/imageflat.png";
import imageflatsfrom from "../../images/image098min.png";
import ButtonOrdBox from "../buttonordbox/buttonordbox";

export function AppHeaderSmall() {
  const location = useLocation().pathname;
  const [isbox, setbox] = useState(true);

  useEffect(() => {
    if (location === "/" || location.indexOf("order") !== -1) {
      setbox(false);
    } else {
      setbox(true);
    }
  }, [location]);

  return (
    <div className={styles.main}>
      <div className={styles.menu}>
        <MenuLeft></MenuLeft>
      </div>

      {isbox && (
        <div className={styles.link}>
          <ButtonOrdBox></ButtonOrdBox>
        </div>
      )}
    </div>
  );
}
export default AppHeaderSmall;
