import styles from "./deepclean.module.css";
import { listLocG } from "../../utils/data";
import CleanType from "../../components/cleantype/cleantype";
import { Helmet } from "react-helmet-async";

function Deepclean() {
  return (
    <div className={styles.main}>
      <Helmet>
        <title>Виды уборки домов и квартир в Порто, генеральная уборка</title>
        <meta
          name="description"
          content="Генеральная уборка домов и квартир в Порто"
        />
        <link rel="canonical" href="https://www.portocleaning.com/deepclean" />
      </Helmet>
      <CleanType
        nameone="Услуги"
        nametwo="Генеральная уборка"
        namethree="Что мы делаем:"
        list={listLocG}
      ></CleanType>
    </div>
  );
}

export default Deepclean;
