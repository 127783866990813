import { motion } from "framer-motion";
import styles from "./orderlink.module.css";

import { NavLink } from "react-router-dom";


type Torderlink = {
  name: string;
  link: string;
  size: number;
};

export const OrderLink = (props: Torderlink) => {
  const backgraund = {
    width: `${props.size}px`,
    height: `${props.size}px`,
    borderRadius: `${props.size}px`,
  };
  return (
    <motion.div
      className={styles.text1}
      whileHover={{ scale: 1.3, transition: { duration: 0.5 } }}
      style={backgraund}
    >
      <NavLink className={styles.link} to={props.link}>
        {props.name}
      </NavLink>
    </motion.div>
  );
};
export default OrderLink;
