import styles from "./slaiderch.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type Tprops = {
  slidesToShow: number;
  list: string[];
};

const SimpleCH = (props: Tprops) => {
  const list = props.list;
  const settings = {
    infinite: true,
    slidesToShow: props.slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: "linear",
  };
  return (
    <div className={styles.maint}>
      <Slider {...settings}>
        {list.map((item, index) => {
          return (
            <div key={index}>
              <span>{item}</span>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default SimpleCH;
