import t1_1 from "../images/text1_1.jpg";
import t1_2 from "../images/text1_2.jpeg";
import t1_3 from "../images/text1_3.jpg";
import logo from "../images/logodog.jpg";
import t11_1 from "../images/text1_1.png";
import t11_2 from "../images/text1_2.png";
import t11_3 from "../images/text1_3.png";
import t11_4 from "../images/text1_4.png";
import t3_1 from "../images/text_03_1.jpg";
import t3_2 from "../images/text_03_2.jpeg";
import t3_3 from "../images/text_03_3.jpg";
import t3_4 from "../images/text_03_4.jpg";
import t3_5 from "../images/text_03_5.jpeg";
import t3_6 from "../images/text_03_6.jpg";
import t3_7 from "../images/text_03_7.jpg";
import t4_1 from "../images/image_4_1.png";
import t4_2 from "../images/image_4_2.png";
import t4_3 from "../images/image_4_3.png";
import t4_4 from "../images/image_4_4.png";
import t4_5 from "../images/image_4_5.png";
import t4_6 from "../images/image_4_6.png";
import t5_1 from "../images/image_5_1.png";
import t5_2 from "../images/image_5_2.png";
import t5_3 from "../images/image_5_3.png";
import t5_4 from "../images/image_5_4.png";
import t5_5 from "../images/image_5_5.png";
import t5_6 from "../images/image_5_6.png";
import t6_1 from "../images/image_6_1.png";
import t6_2 from "../images/image_6_2.png";
import t6_3 from "../images/image_6_3.png";
import t6_4 from "../images/image_6_4.png";
import t6_5 from "../images/image_6_5.png";
import t6_6 from "../images/image_6_6.png";
import t6_7 from "../images/image_6_7.png";
import t7_1 from "../images/image_7_1.png";
import t7_2 from "../images/image_7_2.png";
import t7_3 from "../images/image_7_3.png";
import t7_4 from "../images/image_7_4.png";
import t7_5 from "../images/image_7_5.png";
import t8_1 from "../images/image_8_1.png";
import t8_2 from "../images/image_8_2.png";
import t8_3 from "../images/image_8_3.png";
import t9_1 from "../images/image_9_1.png";
import t9_2 from "../images/image_9_2.png";
import t9_3 from "../images/image_9_3.png";
import t9_4 from "../images/image_9_4.png";
import t9_5 from "../images/image_9_5.png";
import t9_6 from "../images/image_9_6.png";

import { number, string } from "prop-types";

export type Ttext = {
  id: number;
  name: string;
  content: {
    name: string;
    text: string;
    image: string;
  }[];
};

export const texta = [
  {
    id: 1,
    name: "Не очевидная причина распираторных инфекций",
    content: [
      {
        name: "Компьютерная клавиатура может быть в пять раз грязнее сиденья унитаза.",
        text: " Часто можно увидеть, что люди хватаются за еду, используя клавиатуру, но не заботятся о том, чтобы как следует ее очистить. Будь то вечерние напитки с кофеином или закуски, вы всегда можете найти то или иное в качестве компаньона, печатая на клавиатуре компьютера. Во время работы он служит топливом для великих мыслей и идей. Но если вы не понимаете, что ваша клавиатура на самом деле не в рабочем состоянии, возможно, вскоре вам придется купить новую. Хотели бы вы работать на клавиатуре, покрытой жиром, крошками или пятнами, покрытой бактериями? Если нет, постарайтесь регулярно чистить клавиатуру. Это может увеличить срок службы вашей клавиатуры.",
        image: t1_1,
      },
      {
        name: "Избавьтесь от пыли и крошек",
        text: "Перед процессом дезинфекции микробов важно избавиться от крошек и частиц пыли, оставшихся на клавиатуре вашего компьютера. Как только вы выключите компьютер и отсоедините его от сети, переверните клавиатуру и встряхните ее. Вы также можете использовать баллон со сжатым воздухом или фен, чтобы сдуть с клавиатуры остатки царапин.",
        image: t1_2,
      },
      {
        name: "Дезинфекция клавиш",
        text: "Как только все частицы исчезнут с клавиатуры, используйте дезинфицирующую салфетку и оберните ею конец ложки. Теперь проведите дезинфицирующей салфеткой по поверхности и краям клавиатуры. Это поможет очистить грязь и пыль, спрятанную по краям клавиатуры. Особое внимание следует уделить наиболее часто используемым областям, таким как пробел.",
        image: t1_3,
      },
      {
        name: "Заключение",
        text: "Простуда и кашель — распространенные проблемы, которые также могут привести к появлению бактерий и микробов. Не рискуйте своим здоровьем, ведь от таких инфекций можно защититься, просто уделив немного времени чистке клавиатуры. Это так же важно, как и уборка ванной комнаты. ",
        image: logo,
      },
    ],
  },
  {
    id: 2,
    name: "Советы по уборке, чтобы избежать симптомов аллергии",
    content: [
      {
        name: "Сведение к минимуму воздействия аллергенов, если вы страдаете аллергией, улучшит качество вашей жизни.",
        text: "Регулярная уборка дома требует некоторого времени и усилий, и если вы или члены вашей семьи страдаете астмой или симптомами аллергии, вам необходимо помнить о нескольких дополнительных проблемах. Однако с очисткой от аллергии справиться не так уж и сложно. Важно помнить, что полностью избавиться от аллергенов просто невозможно, но вы все равно можете успешно минимизировать их влияние на свое здоровье, а поддержание их на низком уровне будет творить чудеса.",
        image: t11_1,
      },
      {
        name: "Регулярно пылесосьте и мойте пол",
        text: "Пылесосить весь дом один или два раза в неделю — это разумная частота, и она будет иметь огромное значение! Мытье пола после этого также является необходимым шагом. Если вы страдаете аллергией, подумайте о том, чтобы избавиться от ковров, поскольку они подобны магнитам, притягивающим пылевых клещей, и поддерживать их в безупречной чистоте практически невозможно. Убедитесь, что ваш пылесос оснащен HEPA-фильтром, чтобы вся пыль оставалась внутри.",
        image: t11_2,
      },
      {
        name: "Часто стирайте простыни",
        text: "Уборка при аллергии предполагает регулярную стирку не только простыней, но также одеял и подушек. Не забывайте стирать их при температуре не менее 60 градусов по Цельсию, поскольку высокие температуры убивают постельных клещей, и гладьте все, что можно потом гладить.",
        image: t11_3,
      },
      {
        name: "Используйте чистящие средства, подходящие для уборки при аллергии.",
        text: "Многие традиционные чистящие средства содержат химические вещества, которые могут вызвать аллергическую реакцию: иногда даже продукты с сильным запахом могут оказаться слишком сильными для чувствительных людей. Чтобы этого избежать, важно искать чистящие средства, специально отмеченные как безопасные для астматиков и аллергиков. Однако использование натуральных ингредиентов для очистки всегда является эффективным и экологически чистым вариантом!",
        image: t11_4,
      },
      {
        name: "Наведите порядок в своем пространстве",
        text: "Гораздо легче поддерживать регулярную, безопасную для аллергиков процедуру уборки, если под рукой нет ненужных вещей. Благодаря тому, что на столешницах и поверхностях нет беспорядка, вытереть пыль влажной тряпкой можно в кратчайшие сроки!",
        image: t11_4,
      },
      {
        name: "Заключение",
        text: "Чистый дом делает вашу жизнь проще и приятнее. Если вы страдаете аллергией, обеспечив безупречную чистоту в доме, вы облегчите многие симптомы и улучшите свое здоровье.",
        image: logo,
      },
    ],
  },
  {
    id: 3,
    name: "7 лучших методов предотвращения появления токсичной плесени дома",
    content: [
      {
        name: "Плесень - враг здоровой жизни",
        text: "Плесень – маленькое слово, но оно способно заставить любого человека съежиться и вздрогнуть. Хотя она полезна для разложения органических веществ, необнаруженный рост плесени может быть очень вредным для вашего дома. Будь то потолочная плитка или внутренние стены конденсирующихся и протекающих труб, пищевая бумага, одежда и ковер, или задняя сторона гипсокартона, плесень может поселиться где угодно. Это не только влияет на ваше здоровье и является серьезной проблемой, но и устранение плесени обходится довольно дорого. Вы беспокоитесь о том, как предотвратить появление токсичной плесени дома? Вот несколько способов профилактики, прежде чем плесень станет неразрешимой проблемой.",
        image: t3_1,
      },
      {
        name: "Влага - причина плесени",
        text: "Влага – большая проблема и причина роста плесени в доме. Поддержание сухости влажных помещений может оказать большую помощь в предотвращении появления токсичной плесени в доме. Например, просачивание дождевой воды в подвал или скопление протекающих труб могут мгновенно привести к появлению плесени. Даже мокрый ковер из-за пролитой воды или мокрая одежда в стиральной машине, а также мокрые стены после принятия душа могут способствовать распространению плесени в доме. ",
        image: t3_2,
      },
      {
        name: "Ликвидируем условия появления плесени",
        text: "Самый эффективный способ борьбы с плесенью — постоянная ликвидация условий для ее появления и роста. Регулярно вытирайте пыль и мойте полы в квартире, но обязательно высушивайте следы влажной уборки. Проветривайте все помещения. Особенно внимательно следите за ванными комнатами и кухнями. При работе с участками, зараженными плесенью, надевайте на лицо респиратор или медицинскую маску, а на руки — перчатки. Это предохранит легкие от вдыхания плесневых спор, а кожу от прямого контакта с грибком.",
        image: t3_3,
      },
      {
        name: "Перекись водорода",
        text: "Противогрибковый и антибактериальный раствор, который есть во многих аптечках, — хорошее средство от плесени. Налейте в распылитель перекись водорода с концентрацией 3%. Его можно использовать на кухонной технике, столешницах, ванне, твердых полах и поверхностях стен. Сначала проверьте незаметный участок, чтобы предотвратить обесцвечивание или повреждение. Пропитайте заплесневелую поверхность жидкостью и оставьте на десять минут. Затем потрите область с усилием, достаточным для удаления пятен, и вытрите насухо.",
        image: t3_4,
      },
      {
        name: "Эфирные масла",
        text: "Многие эфирные масла используются не только для ароматерапии. Эссенции обладают антисептическими и дезодорирующими свойствами, отпугивают насекомых и помогают избавиться от плесени. Масло чайного дерева, розмарина и лаванды — натуральные фунгициды, который быстро очищают грибковые споры и предотвращают их повторное появление. Смешайте одну чайную ложку масла чайного дерева с 200 мл чистой воды, хорошо взболтайте в закрытой емкости. Распылите средство на заплесневелый участок, оставьте высыхать на час. Затем протрите поверхность сухим полотенцем или тряпкой из микрофибры. Некоторые эфирные масла раздражают кожу, поэтому рекомендуется использовать перчатки.",
        image: t3_5,
      },
      {
        name: "Белый уксус",
        text: "Слабокислый пищевой продукт очищает и дезинфицирует. Уксус безопасен для большинства поверхностей, а специфический запах быстро выветривается. Он может убить 82% видов плесени, включая сложную черную. Лучше использовать неразбавленную кислоту, так повышается ее эффективность. Налейте белый уксус в пульверизатор, распылите на заплесневелую поверхность и оставьте на час. Протрите участок чистой водой и дайте ему высохнуть. Уксус испарится в течение пары часов и запах исчезнет.",
        image: t3_6,
      },
      {
        name: "Лимоны",
        text: "Лимон может быть антисептиком, отбеливателем, пятновыводителем и очистителем. Этот фрукт поможет удалить черную плесень. Высокая концентрация кислоты в лимонах разрушает мицелий, помогает легко удалить налет и дезинфицирует поверхность. Выдавите сок трех — пяти лимонов в чашку и нанесите его на заплесневелый участок. Оставьте на пять минут, а затем протрите поверхность влажным полотенцем. Смесь можно наносить повторно для наилучшего очищения и не смывать ее",
        image: t3_7,
      },
      {
        name: "Пищевая сода",
        text: "У соды множество преимуществ в бытовом использовании, это отличное чистящее и дезинфицирующее средство. Она безопасна для детей и домашних животных, поэтому нередко входит в состав профессиональных средств для уборки. Помимо удаления плесени, сода отлично впитывает запахи и влагу. Смешайте половину столовой ложки соды с 0,5 л воды и взболтайте в пульверизаторе. Распылите состав на пораженные плесенью участки, удалите налет щеткой и ополосните водой. После этого снова нанесите содовый раствор и оставьте высыхать. Этот способ позволяет уничтожить оставшуюся плесень и предотвратит появление новой.",
        image: t3_2,
      },
      {
        name: "Антисептическая грунтовка",
        text: "Проверенный способ с эффективным средством из строительного магазина. Перед нанесением грунтовки на зараженную поверхность постарайтесь максимально счистить с нее плесень. В случае глубокого прорастания мицелия со стен удаляют не только обои и краску, но также слои штукатурки вплоть до бетона и кирпича. Раствор продается как в готовом виде, так и для разведения водой.",
        image: logo,
      },
    ],
  },
  {
    id: 4,
    name: "Делаем генеральную уборку ванной комнаты: пошаговый план",
    content: [
      {
        name: "Начните генеральную уборку с подготовки.",
        text: "Начните генеральную уборку с подготовки. Уберите все предметы с полок, раковины и душевой зоны, чтобы освободить поверхности для чистки. Не забудьте надеть резиновые перчатки для защиты рук от химических средств. Откройте окно или включите вентиляцию, чтобы обеспечить хорошую циркуляцию воздуха. Подготовьте все необходимые чистящие средства, губки и тряпки заранее, чтобы не отвлекаться в процессе. Это позволит вам сконцентрироваться на уборке и сэкономить время.",
        image: t4_1,
      },
      {
        name: "Первый шаг — очистка стен и плитки.",
        text: "Первый шаг — очистка стен и плитки. Нанесите чистящее средство на плитку, уделяя особое внимание швам, где часто скапливаются грязь и плесень. Оставьте средство на несколько минут, чтобы оно подействовало, затем протрите плитку щеткой или губкой. Особое внимание уделите углам и местам, где плитка соприкасается с полом. Для сложных загрязнений можно использовать раствор из уксуса и соды. Промойте плитку теплой водой и вытрите насухо, чтобы избежать разводов.",
        image: t4_2,
      },
      {
        name: "Далее займитесь чисткой сантехники — раковины, ванны и унитаза.",
        text: "Нанесите специальное средство для удаления известкового налета на все поверхности и оставьте на несколько минут. После этого используйте губку или щетку для удаления налета и загрязнений. Не забывайте о труднодоступных местах, таких как кран и сливные отверстия. Протрите раковину и ванну сухой тряпкой, чтобы вернуть им блеск. Важно чистить и дезинфицировать унитаз, уделяя внимание не только внутренней части, но и ручке, сидению и внешней поверхности.",
        image: t4_3,
      },
      {
        name: "Зеркала и стеклянные поверхности требуют особого ухода.",
        text: "Чтобы избежать разводов, используйте специальное средство для стекол и микрофибровую тряпку. Нанесите средство на стекло и протрите круговыми движениями. Обратите внимание на углы и края зеркал, где часто накапливается пыль и грязь. Полировка сухой тряпкой придаст стеклам дополнительный блеск. Это не только сделает ванную комнату визуально чище, но и улучшит освещенность за счет отражения света.",
        image: t4_4,
      },
      {
        name: "Затем перейдите к чистке полов и вентиляции.",
        text: "Начните с удаления пыли и грязи с помощью пылесоса или веника. Нанесите чистящее средство на пол и протрите его шваброй, уделяя внимание углам и труднодоступным местам. Для борьбы с плесенью и неприятными запахами не забывайте чистить вентиляционную решетку и вытяжку. Регулярная чистка вентиляции предотвратит накопление влаги, что особенно важно в ванной комнате. Это шаг часто упускается из виду, но он помогает поддерживать свежесть в помещении.",
        image: t4_5,
      },
      {
        name: "Завершите уборку расстановкой вещей на места.",
        text: "Протрите все флаконы, мыльницы и аксессуары, прежде чем вернуть их обратно на полки. Проверьте срок годности косметики и выбросьте ненужные предметы. Добавьте освежитель воздуха или ароматические свечи, чтобы завершить образ чистой и уютной ванной комнаты. Теперь ваша ванная блестит чистотой и готова к использованию! Регулярное проведение такой генеральной уборки поможет сохранить ее в идеальном состоянии.",
        image: t4_6,
      },
    ],
  },
  {
    id: 5,
    name: "Делаем генеральную уборку кухни: пошаговый план",
    content: [
      {
        name: "Начните генеральную уборку с подготовки.",
        text: "Начните генеральную уборку кухни с подготовки всех необходимых средств и инструментов. Уберите все предметы с рабочих поверхностей, уберите ненужные вещи из шкафов и полок. Для эффективной уборки подготовьте моющие средства, губки, щетки и тряпки заранее. Наденьте перчатки для защиты рук, откройте окно для вентиляции помещения. Это поможет создать комфортные условия и сэкономить время. Теперь можно приступить к уборке по плану.",
        image: t5_1,
      },
      {
        name: "Первый шаг — очистка верхних поверхностей и шкафов.",
        text: "Протрите шкафы и полки сверху, уделяя внимание углам, где может скапливаться пыль. Используйте мягкое чистящее средство или раствор из воды и уксуса для удаления жира и грязи с внешних и внутренних поверхностей шкафов. Особое внимание уделите ручкам и стыкам, где может скапливаться грязь. После протирки протрите шкафы сухой тряпкой, чтобы избежать разводов. Этот шаг поможет обновить внешний вид кухни.",
        image: t5_2,
      },
      {
        name: "Затем перейдите к чистке бытовой техники.",
        text: "Начните с холодильника: вытащите все продукты, выбросьте испорченное и протрите полки и стенки. Нанесите специальное чистящее средство на плиту и духовку, оставьте на несколько минут для растворения жира. Чистка микроволновой печи может быть проще, если нагреть в ней стакан воды с лимоном — это размягчит загрязнения. Протрите технику мягкой губкой или тряпкой, уделяя внимание кромкам и ручкам. Тщательная чистка техники улучшит её работу и продлит срок службы.",
        image: t5_3,
      },
      {
        name: "Следующий этап — чистка рабочей поверхности и раковины. ",
        text: "Для начала нанесите чистящее средство на столешницу, уделяя внимание швам и углам. Протрите поверхность губкой или тряпкой, тщательно убирая все пятна. Раковину можно почистить с помощью раствора соды и уксуса для удаления налета и жира. Используйте щетку для очистки сливов и крана. Завершите чистку, протерев поверхность сухой тряпкой для блеска.",
        image: t5_4,
      },
      {
        name: "Затем переходите к чистке пола.",
        text: "Начните с того, чтобы пропылесосить пол, чтобы убрать крупные частицы грязи и крошки. После этого нанесите моющее средство на пол и протрите его шваброй или тряпкой, уделяя внимание углам и труднодоступным местам. Если на полу есть стойкие пятна, их можно удалить с помощью специального средства для полов. Не забывайте промывать тряпку или менять воду по мере загрязнения. Чистый пол создаст ощущение порядка и завершенности уборки.",
        image: t5_5,
      },
      {
        name: "Завершите уборку расстановкой вещей на места.",
        text: "Протрите и верните на место кухонную утварь, проверьте чистоту фартуков, подставок и других мелочей. Можно дополнительно освежить помещение, используя ароматизаторы или проветривание. Важный момент — убедиться, что все шкафы и ящики организованы удобно для дальнейшего использования. Теперь ваша кухня чиста и готова к новым кулинарным экспериментам! Поддерживайте регулярную уборку, чтобы избегать накопления грязи.",
        image: t5_6,
      },
    ],
  },
  {
    id: 6,
    name: "Делаем генеральную уборку в доме с питомцами",
    content: [
      {
        name: "Животные в доме",
        text: "",
        image: t6_1,
      },
      {
        name: "Первый шаг — очистка верхних поверхностей и шкафов.",
        text: "Животные в доме приносят радость, но требуют дополнительного внимания к чистоте. Начните генеральную уборку с того, чтобы убрать все вещи домашних питомцев: игрушки, миски, лежанки. Это позволит вам легко добраться до поверхностей и начать уборку с чистого листа. Важно регулярно стирать подстилки и мягкие игрушки животных, чтобы избавляться от запахов и бактерий. Пока вещи стираются, можно приступить к следующему этапу уборки. Регулярная смена и чистка этих предметов поможет уменьшить количество аллергенов в доме.",
        image: t6_2,
      },
      {
        name: "Пылесос — ваш главный союзник в борьбе с шерстью",
        text: "Пылесос — ваш главный союзник в борьбе с шерстью и пылью. Начните уборку с тщательного пылесошения всех поверхностей, включая ковры, мебель и углы. Для уборки шерсти лучше всего подходят пылесосы с мощными фильтрами, такими как HEPA, которые удерживают мелкие частицы. Не забудьте почистить под мебелью и в местах, где ваш питомец часто спит или играет. Особенно важно уделять внимание мягкой мебели, так как на ней часто скапливаются шерсть и грязь. Регулярное пылесошение сократит количество шерсти в доме.",
        image: t6_3,
      },
      {
        name: "Следующий этап мытье полов",
        text: "После пылесоса перейдите к чистке полов. Животные часто приносят с улицы грязь, поэтому полы нужно мыть тщательно, уделяя внимание местам, где они часто находятся. Используйте безопасные для животных чистящие средства, избегая агрессивной химии. Лучше всего подходят средства на основе натуральных компонентов, такие как уксус или лимонный сок. Не забывайте тщательно промывать тряпку или швабру, чтобы не распространять грязь по дому. Мытье полов избавит вас от пятен и запахов.",
        image: t6_4,
      },
      {
        name: "Тщательная уборка шерсти",
        text: "Затем займитесь мебелью и тканями. Используйте специальные ролики или липкие щетки для удаления шерсти с диванов, кресел и подушек. Если на мебели или шторах есть неприятные запахи, используйте освежитель для тканей или приготовьте раствор из соды и воды для нейтрализации запахов. Также стоит периодически чистить или стирать чехлы на мебель, если они съемные. Это не только придаст свежий вид вашему дому, но и поможет избавиться от пыли и аллергенов. Уделите внимание деталям, таким как занавески и пледы, которые также накапливают шерсть.",
        image: t6_5,
      },
      {
        name: "Особое внимание уделите очистке воздуха в доме.",
        text: "Особое внимание уделите очистке воздуха в доме. Использование воздухоочистителей с фильтрами поможет уменьшить количество шерсти, пыли и запахов, связанных с животными. Регулярно проветривайте помещения, чтобы избавиться от застоявшегося воздуха. Также полезно использовать ароматизаторы или эфирные масла, которые безопасны для животных. Однако избегайте сильных химических запахов, так как они могут раздражать домашних питомцев. Чистый воздух улучшит общее состояние дома и сделает его более комфортным для всех.",
        image: t6_6,
      },
      {
        name: "Завершите уборку обработкой всех поверхностей, с которыми контактируют ваши питомцы.  ",
        text: "Завершите уборку обработкой всех поверхностей, с которыми контактируют ваши питомцы. Протрите их миски, коврики и игрушки, используя горячую воду и мягкое чистящее средство. Обязательно следите за чистотой лотков для кошек и подстилок для собак. Регулярная чистка этих предметов предотвращает накопление бактерий и неприятных запахов. Уборка дома с животными требует больше усилий, но правильная организация процесса делает его проще и эффективнее. Поддерживайте регулярную чистку, чтобы дом оставался уютным и свежим, несмотря на наличие питомцев.",
        image: t6_7,
      },
    ],
  },
  {
    id: 7,
    name: "Топ-5 самых сложных загрязнений в доме и как с ними справляться",
    content: [
      {
        name: "Жирные пятна на кухонных поверхностях",
        text: "Одно из самых сложных загрязнений в доме — жирные пятна на кухонных поверхностях. Они накапливаются на плитке, кухонных шкафах и особенно вокруг плиты. Чтобы справиться с этим, используйте смесь воды и уксуса в пропорции 1:1 или специальное средство для удаления жира. Нанесите раствор на загрязненную поверхность и оставьте на несколько минут, чтобы жир размягчился. После этого протрите губкой или мягкой тряпкой. Для старых пятен можно добавить немного пищевой соды для усиления эффекта.",
        image: t7_1,
      },
      {
        name: "Известковый налет в ванной",
        text: "Известковый налет в ванной также относится к трудным загрязнениям, особенно на кранах, плитке и душевой кабине. Чтобы эффективно избавиться от налета, используйте лимонную кислоту или уксус. Нанесите раствор на поверхности, оставьте на 10-15 минут, затем аккуратно протрите губкой или мягкой щеткой. Для стойкого налета можно использовать зубную щетку для чистки труднодоступных мест. Регулярная профилактика и протирание сухой тряпкой после каждого использования ванной помогут избежать его накопления.",
        image: t7_5,
      },
      {
        name: "Плесень",
        text: "Следующим проблемным загрязнением является плесень, которая часто образуется в местах с повышенной влажностью — в ванной или на кухне. Чтобы бороться с плесенью, используйте специальные антиплесневые средства или смесь уксуса и воды. Нанесите раствор на пораженные участки и оставьте на 30 минут, затем удалите плесень с помощью губки. Для предотвращения её повторного появления регулярно проветривайте помещения и следите за влажностью. Важно не игнорировать плесень, так как она может быть вредна для здоровья.",
        image: t7_4,
      },
      {
        name: "Шерсть домашних животных",
        text: "Шерсть домашних животных, особенно на мебели и коврах, может стать настоящей проблемой, если в доме есть кошки или собаки. Для эффективного удаления шерсти используйте пылесос с насадкой для мебели или специальные липкие ролики. Также можно применить резиновую перчатку: наденьте её и проведите рукой по поверхности, чтобы собрать шерсть. Регулярная стирка покрывал и чехлов на мебели поможет сократить количество шерсти в доме. Регулярная чистка пылесосом и протирка мебели станут надежным способом борьбы с шерстью.",
        image: t7_3,
      },
      {
        name: "Пятна от кофе, чая или вина на коврах и обивке мебели",
        text: "Пятна от кофе, чая или вина на коврах и обивке мебели требуют быстрой реакции. Чем быстрее вы начнете удалять пятно, тем легче оно отойдет. Смочите загрязнение холодной водой и промокните чистой тканью, затем нанесите на пятно смесь из соды и воды. Оставьте смесь на пятне на несколько минут, после чего протрите чистой тряпкой. Если пятно застарелое, можно использовать профессиональные пятновыводители для текстиля.",
        image: t7_2,
      },
    ],
  },
  {
    id: 8,
    name: "Топ-3 самых опасных загрязнений в доме и как с ними справляться",
    content: [
      {
        name: "Первое опасное загрязнение — это плесень",
        text: "Первое опасное загрязнение — это плесень, которая часто встречается в помещениях с повышенной влажностью, таких как ванная и кухня. Плесень может вызывать аллергию, респираторные заболевания и ухудшать общее состояние здоровья. Чтобы эффективно бороться с плесенью, важно использовать антиплесневые средства или натуральные растворы, такие как смесь уксуса и воды. Наносите средство на пораженные участки, оставьте на несколько минут, затем тщательно промойте. Чтобы предотвратить её повторное появление, проветривайте помещения и следите за уровнем влажности. Установите вентиляторы или осушители воздуха для снижения влажности.",
        image: t8_1,
      },
      {
        name: "Бактерии на кухонных поверхностях",
        text: "Второе опасное загрязнение — бактерии на кухонных поверхностях, особенно на разделочных досках и раковине. На этих поверхностях легко размножаются болезнетворные микроорганизмы, которые могут стать причиной пищевых отравлений и инфекций. Для борьбы с бактериями регулярно дезинфицируйте поверхности, используя антибактериальные чистящие средства или смесь воды и уксуса. Особое внимание уделяйте обработке мест, где готовится сырое мясо и рыба. Меняйте кухонные губки и тряпки каждую неделю, так как они могут стать рассадником бактерий. Соблюдение этих мер поможет поддерживать кухню в гигиенически чистом состоянии.",
        image: t8_2,
      },
      {
        name: "Пыль",
        text: "Третьим опасным загрязнением является пыль, которая содержит аллергенные частицы, такие как пылевые клещи и микрочастицы, вредные для дыхательной системы. Пыль особенно опасна для людей с аллергией и астмой, так как она вызывает приступы удушья и раздражение. Регулярное пылесошение с использованием пылесоса с HEPA-фильтром, а также влажная уборка помогут снизить количество пыли в доме. Не забывайте чистить мебель, шторы и ковры, на которых накапливается много пыли. Регулярное проветривание помещений и контроль влажности также помогут уменьшить пылеобразование.",
        image: t8_3,
      },
    ],
  },
  {
    id: 9,
    name: "Как сохранить дом в чистоте с детьми и животными",
    content: [
      {
        name: "Начните с организации пространства",
        text: "Сохранить дом в чистоте с детьми и домашними животными может быть непростой задачей, но правильный подход значительно упростит этот процесс. Начните с организации пространства: создайте зоны для игр и отдыха, где легко поддерживать порядок. Убедитесь, что у вас есть достаточное количество корзин для игрушек и принадлежностей, чтобы дети могли легко убирать за собой. Регулярно проводите разбор игрушек, избавляясь от ненужных или сломанных предметов, что уменьшит количество беспорядка. Обучение детей убирать свои вещи с раннего возраста не только приучит их к порядку, но и сделает уборку более простой для вас.",
        image: t9_1,
      },
      {
        name: "Регулярное удаление шерсти",
        text: "Для поддержания чистоты в доме с животными важен регулярный уход за шерстью. Проводите пылесосение не реже двух раз в неделю, особенно в местах, где ваши питомцы часто находятся. Используйте пылесос с HEPA-фильтром, который эффективно удаляет шерсть и аллергены. Также важно регулярно мыть и чистить лежанки, игрушки и миски питомцев, чтобы избежать неприятных запахов. Периодическая стирка покрывал и подушек поможет уменьшить количество аллергенов и пыли. Использование специальных резинок или липких роликов для удаления шерсти с одежды и мебели также значительно облегчит жизнь.",
        image: t9_2,
      },
      {
        name: "Набор для быстрого удаления загрязнений.",
        text: "Для борьбы с грязью и пятнами, которые могут оставлять дети и животные, заранее подготовьте набор для быстрого удаления загрязнений. Включите в него уксус, соду, микрофибровые тряпки и специальные средства для чистки. Если заметили пятно, действуйте незамедлительно: чем быстрее вы его уберете, тем легче будет удалить его полностью. Установите правила по использованию еды и напитков в определенных зонах, чтобы минимизировать количество пролитых жидкостей. Это поможет сократить количество сложных загрязнений и облегчит дальнейшую уборку. Регулярно проверяйте зону, где играют дети и животные, чтобы избежать скопления грязи и пыли.",
        image: t9_3,
      },
      {
        name: "Проветривание",
        text: "Не забывайте о важности чистого воздуха в доме. Животные и дети могут вызывать запахи и аллергены, поэтому регулярная уборка и проветривание помещений необходимы. Установите воздухоочистители или используйте комнатные растения, которые помогают очищать воздух. Также полезно проветривать комнаты не менее 10-15 минут каждый день, чтобы избавиться от застоявшегося воздуха. Освежители воздуха можно использовать, но выбирайте натуральные и безопасные для детей и животных варианты. Таким образом, вы сможете создать более комфортную и здоровую атмосферу в своем доме.",
        image: t9_4,
      },
      {
        name: "Регулярная чистка поверхностей и мебели",
        text: "Еще одним важным шагом является регулярная чистка поверхностей и мебели. Уделите внимание труднодоступным местам, где может скапливаться пыль и грязь, например, за диваном и под кроватью. Для этого можно использовать длинные микрофибровые щетки и пылесос с насадками для углов. Применение пароочистителей поможет эффективно удалить бактерии и аллергены с поверхности без использования химических средств. Кроме того, протирайте поверхности антисептическими салфетками, особенно в кухонных и детских зонах, чтобы поддерживать гигиеничность. Это значительно снизит риск распространения инфекций и улучшит общее состояние вашего дома.",
        image: t9_5,
      },
      {
        name: "Будьте терпеливыми и позитивными",
        text: "Наконец, будьте терпеливыми и позитивными в процессе поддержания чистоты. С детьми и животными порядок поддерживать сложно, но не забывайте, что основная цель — создать уютную атмосферу. Превратите уборку в игру, чтобы дети могли участвовать и получать удовольствие от процесса. Это не только научит их ответственности, но и сделает уборку более эффективной. Помните, что идеальный порядок не всегда достижим, и лучше всего стремиться к чистоте, которая позволяет всем членам семьи чувствовать себя комфортно. Сохраняя позитивный настрой и следуя простым советам, вы сможете поддерживать дом в чистоте и порядке.",
        image: t9_6,
      },
    ],
  },
];
