import { useState } from "react";
import styles from "./maismenoslite.module.css";

type TMaismenos = {
  func: (it: any, col: number) => void;
  param: any;
};

export function Maismenoslite(props: TMaismenos) {
  const setnum = props.func;

  const [col, setCol] = useState(0);

  const onclickmais = () => {
    setCol(col + 1);
    setnum(props.param, 1);
  };

  const onclickmenos = () => {
    if (col > 0) {
      setCol(col - 1);
      setnum(props.param, -1);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.box} onClick={onclickmais}>
        +
      </div>
      <div className={styles.name}>{col}</div>
      <div className={styles.box} onClick={onclickmenos}>
        -
      </div>
    </div>
  );
}
export default Maismenoslite;
