import styles from "./iconcolor.module.css";

type Ticonlink = {
  homeone: string;
  hometwo: string;
  rad: number;
  action: boolean;
};

export const IconColor = (props: Ticonlink) => {
  let bgone = props.homeone;

  if (props.action) {
    bgone = props.hometwo;
  }

  const backgraundihomeone = {
    backgroundImage: `url(${bgone})`,
    width: `${props.rad}px`,
    height: `${props.rad}px`,
    borderRadius: `${props.rad}px`,
  };

  return (
    <div
      className={styles.action}
      style={{
        width: `${props.rad}px`,
        height: `${props.rad}px`,
        borderRadius: `${props.rad}px`,
      }}
    >
      <div className={styles.icon} style={backgraundihomeone}></div>
    </div>
  );
};
export default IconColor;
