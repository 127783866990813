import styles from "./contact.module.css";
import { dataCall } from "../../utils/data";
import Cardсall from "../cardсall/cardсall";
import { useEffect, useState } from "react";
import { useResize } from "../../utils/hook";
import Slider from "react-slick";

type Tprops = {
  work: boolean;
};

function Contact({ work }: Tprops) {
  const wl = useResize().width;
  const [col, setCol] = useState(1);
  const [widthd, setwidthd] = useState({ width: `1100px` });

  useEffect(() => {
    if (wl > 1350) {
      setCol(4);
      setwidthd({ width: `1200px` });
    } else {
      if (wl > 1000) {
        setCol(3);
        setwidthd({ width: `900px` });
      } else {
        if (wl > 700) {
          setCol(2);
          setwidthd({ width: `620px` });
        } else {
          setCol(1);
          setwidthd({ width: `100%` });
        }
      }
    }
  }, [wl]);

  const settings = {
    infinite: true,
    slidesToShow: col,
    slidesToScroll: 1,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 3000,
    cssEase: "linear",
    swipeToSlide: true,
    dots: true,
  };

  return (
    <div className={styles.main}>
      <h2
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          margin: 0,
          paddingTop: 5,
          paddingBottom: 0,
        }}
      >
        Свяжитесь с нами!
      </h2>
      {!work && (
        <>
          <h2
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              margin: 0,
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            Чистота начинается с первого звонка!{" "}
          </h2>
          <h3
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              margin: 0,
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            Если у вас есть вопросы, хотите узнать больше о наших услугах или
            готовы заказать уборку — мы всегда на связи.
          </h3>
        </>
      )}

      <div className={styles.stringtext} style={widthd}>
        <Slider {...settings}>
          {dataCall.map((item, index) => {
            return (
              <div className={styles.box} key={index}>
                <div className={styles.boxnew} key={index}>
                  <Cardсall
                    name={item.name}
                    actionType={item.actionType}
                    icon={item.icon}
                    iconw={item.iconw}
                    key={index}
                    link={item.link}
                    action={item.action}
                  ></Cardсall>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>

      {!work && (
        <div>
          <h3 style={{ paddingLeft: 20, paddingRight: 20 }}>
            Мы оказываем услуги в районе Большого Порто.
          </h3>
          <h3 style={{ paddingLeft: 20, paddingRight: 20 }}>
            Готовы приехать к вам в любой район и вернуть в ваш дом идеальную
            чистоту!
          </h3>
        </div>
      )}
    </div>
  );
}

export default Contact;
/*useEffect(() => {}, []);
  const [isactiv, setIsactiv] = useState(0);*/
