import styles from "./cleantype.module.css";
import Status from "../../components/status/status";
import Compoundnew from "../../components/compoundnew/compoundnew";
import None from "../none/none";
import { NavLink } from "react-router-dom";

type Tprops = {
  nameone: string;
  nametwo: string;
  namethree: string;
  list: {
    name: string;
    ref: string;
    work: string[];
  }[];
};

function CleanType({ nameone, nametwo, namethree, list }: Tprops) {
  return (
    <div className={styles.main}>
      <NavLink to={"/clean"} className={styles.link}>
        <h2 style={{ paddingLeft: 20, paddingRight: 20 }}>{nametwo}</h2>
        <h2 style={{ paddingLeft: 20, paddingRight: 20 }}>{namethree}</h2>
      </NavLink>
      <Compoundnew listloc={list}></Compoundnew>
    </div>
  );
}

export default CleanType;
