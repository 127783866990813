import styles from "./iconlink.module.css";

import { useState } from "react";

type Ticonlink = {
  homeone: string;
  hometwo: string;
  rad: number;
  action: string;
};

export const IconLink = (props: Ticonlink) => {
  let bgone = props.homeone;
  let bgtwo = props.hometwo;

  const backgraundihomeone = {
    backgroundImage: `url(${bgone})`,
    width: `${props.rad}px`,
    height: `${props.rad}px`,
    borderRadius: `${props.rad}px`,
  };

  const backgraundihometwo = {
    backgroundImage: `url(${bgtwo})`,
    width: `${props.rad}px`,
    height: `${props.rad}px`,
    borderRadius: `${props.rad}px`,
  };

  const [icon, setIcon] = useState(backgraundihomeone);

  return (
    <div
      className={styles.action}
      style={{
        width: `${props.rad}px`,
        height: `${props.rad}px`,
        borderRadius: `${props.rad}px`,
      }}
    >
      <div
        className={styles.icon}
        style={icon}
        onMouseEnter={() => setIcon(backgraundihometwo)}
        onMouseLeave={() => setIcon(backgraundihomeone)}
      ></div>
    </div>
  );
};
export default IconLink;
