import { useEffect, useState } from "react";
import styles from "./menuleft.module.css";
import iconmenu from "../../images/menu.png";
import iconmenux from "../../images/xmenu.png";
import { listMenu } from "../../utils/data";
import { NavLink } from "react-router-dom";
import { motion, Variants } from "framer-motion";

function MenuLeft() {
  const [open, setopen] = useState(false);

  useEffect(() => {
    if (open === true) {
      document.addEventListener("click", hendlerClick);
    }
    if (open === false) {
      document.removeEventListener("click", hendlerClick);
    }
  }, [open]);

  const hendlerClick = (evt: any) => {
    if (!evt.target.className.includes("icon")) {
      if (open === true) {
        if (evt.target.className) {
          if (!evt.target.className.includes("menulist")) {
            setopen(false);
          }
        }
      }
    }
  };

  return (
    <motion.div
      className={styles.main}
      initial={false}
      animate={open ? "open" : "closed"}
    >
      <motion.div
        className={styles.icon}
        whileTap={{ scale: 0.8 }}
        onClick={() => {
          setopen(!open);
        }}
      >
        <img src={open ? iconmenux : iconmenu} alt="menu"></img>
      </motion.div>

      <motion.nav
        className={styles.menu}
        variants={{
          open: {
            clipPath: "inset(-5% -5% -5% -5% round 50px)",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.7,
              delayChildren: 0.3,
              staggerChildren: 0.05,
            },
          },
          closed: {
            clipPath: "inset(5% 90% 95% 10% round 10px)",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.3,
            },
          },
        }}
        style={{ pointerEvents: open ? "auto" : "none" }}
      >
        <ul className={styles.menulist}>
          {listMenu.map((item, index) => {
            return (
              <motion.div whileHover={{ scale: 1.2 }} key={index}>
                <NavLink
                  className={styles.menuitem}
                  style={({ isActive }) => {
                    return isActive ? { color: "rgb(107, 215, 210)" } : {};
                  }}
                  to={item.link}
                  key={index}
                  onClick={() => {
                    setopen(!open);
                  }}
                >
                  {item.name}
                </NavLink>
              </motion.div>
            );
          })}
        </ul>
      </motion.nav>
    </motion.div>
  );
}

export default MenuLeft;
