import React, { useEffect, useState } from "react";
import styles from "./formcallnew.module.css";
import { InputMask } from "@react-input/mask";
import { sendMassege } from "../../api/telegramm";
import { motion, AnimatePresence } from "framer-motion";

const Formcallnew = () => {
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const [errortext, setErrortext] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [ok, setok] = useState(false);
  const [oktext, setoktext] = useState("");

  const [phoneError, setPhoneError] = useState("");
  const [formValid, setFormValid] = useState(false);

  const changeName = (e: any) => {
    setName(e.target.value);
  };

  const textAnimationt = {
    hidden: {
      opacity: 0,
    },
    visible: () => ({
      opacity: 1,
      transition: { delay: 0, duration: 0.2 },
    }),
  };

  const changeDescription = (e: any) => {
    setDescription(e.target.value);
  };

  const changePhone = (e: any) => {
    const fon = e.target.value;
    setPhone(e.target.value);

    if (fon.length < 16) {
      setPhoneError("Слишком короткий номер");
    }
    if (fon.length === 0) {
      setPhoneError("телефон не может быть пустым");
    }
    if (fon.length === 16) {
      setPhoneError("");
      setFormValid(true);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const message: string = `имя: ${name}, телефон :${phone} просит перезвонить ${description}`;
      await sendMassege(message);
      setok(true);
      setoktext("Мы перезвоним вам в течение 30 мин.");
    } catch (e) {
      setError(true);
    } finally {
      setFormValid(false);
    }
  };

  return (
    <div className={styles.main}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.textform}>Кому перезвонить:</div>
        <input
          className={styles.userBox}
          type="text"
          value={name}
          name="name"
          onChange={changeName}
          placeholder="Введите имя"
          autoComplete="on"
        />

        <InputMask
          className={styles.userBox}
          type="text"
          mask="+351 ___ ___ ___"
          replacement={{ _: /\d/ }}
          value={phone}
          name="phone"
          onChange={changePhone}
          placeholder="Введите телефон"
          autoComplete="on"
        />
        <div className={styles.error}>{phoneError}</div>
        <input
          className={`${styles.userBox} ${styles.userBoxcom}`}
          type="text"
          value={description}
          name="description"
          onChange={changeDescription}
          placeholder="Комментарий"
           autoComplete="on"
        />
        {formValid && (
          <motion.button
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            type="submit"
            className={` ${styles.textbutton}`}
            onClick={handleSubmit}
            variants={textAnimationt}
          >
            Отправить
          </motion.button>
        )}
        {ok && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className={styles.textform}
          >
            {oktext}
          </motion.div>
        )}
        {error && (
          <>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className={styles.textform}
            >
              Что-то не так,пожалуйста, попробуйте написать в телеграмм
            </motion.div>
          </>
        )}
      </form>
    </div>
  );
};

export default Formcallnew;
/*const onSubmit = (e: React.FormEvent) => {
  e.preventDefault();
  setEmail(empty);
  setName(empty);
  setDescription(empty);
  fetch("http:/localhost:5000/telegram", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, phone, email, description }),
  })
    .then((response) => response.json())
    .then((result) => alert(result.response.msg));
};*/
