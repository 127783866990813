import { configureStore, combineReducers } from "@reduxjs/toolkit";

import screenReducer from "./screen";
import orderReducer from "./orderst";
import userReducer from "./user";

export const initialState = {
  screen: false,
};

const rootReducer = combineReducers({
  screen: screenReducer,
  user: userReducer,
  orderst: orderReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispath = typeof store.dispatch;
