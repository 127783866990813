import { useEffect, useState } from "react";
import styles from "./wecan.module.css";
import { dataCompany } from "../../utils/data";
import { listtext } from "../../utils/data";
import Runstring from "../runstring/runstring";
import Cardcompany from "../cardcompany/cardcompany";
import { useResize } from "../../utils/hook";
import Slider from "react-slick";

function Wecan() {
  const wl = useResize().width;
  const [col, setCol] = useState(1);
  const [widthd, setwidthd] = useState({ width: `1100px` });

  useEffect(() => {
    if (wl > 1350) {
      setCol(4);
      setwidthd({ width: `1200px` });
    } else {
      if (wl > 1000) {
        setCol(3);
        setwidthd({ width: `900px` });
      } else {
        if (wl > 700) {
          setCol(2);
          setwidthd({ width: `620px` });
        } else {
          setCol(1);
          setwidthd({ width: `100%` });
        }
      }
    }
  }, [wl]);

  const settings = {
    infinite: true,
    slidesToShow: col,
    slidesToScroll: 1,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 3000,
    cssEase: "linear",
    swipeToSlide: true,
    dots: true,
  };

  return (
    <div className={styles.main}>
      <div className={styles.section_1}>
        <h2 style={{ paddingLeft: 20, paddingRight: 20 }}>
          Мы компания домашнего сервиса и мы можем:
        </h2>
        <h3 style={{ paddingLeft: 20, paddingRight: 20 }}>
          Сделать вашу жизнь ярче
        </h3>
        <h3 style={{ paddingLeft: 20, paddingRight: 20 }}>
          Eсли не тратить драгоценное время на домашнюю рутину, можно...
        </h3>
        <div className={styles.strings}>
          <Runstring strings={listtext} delay={70}></Runstring>
        </div>
      </div>

      <div className={styles.section_1}>
        <h3 style={{ paddingLeft: 20, paddingRight: 20 }}>
          Сделать вашу жизнь проще
        </h3>
        <h3 style={{ paddingLeft: 20, paddingRight: 20 }}>потому что у нас</h3>
        <div className={styles.stringtext} style={widthd}>
          <Slider {...settings}>
            {dataCompany.map((item, index) => {
              return (
                <div className={styles.box} key={index}>
                  <div className={styles.boxnew} key={index}>
                    <Cardcompany
                      name={item.name}
                      actionType={item.actionType}
                      icon={item.icon}
                      iconw={item.iconw}
                      key={index}
                    ></Cardcompany>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Wecan;
