import React, { useState, useEffect } from "react";
import iconfon from "../../images/iconfon.jpg";
import styles from "./clients.module.css";

const GoogleReviews = () => {
  const [reviews, setReviews] = useState([]);
  const placeId = "ВАШ_PLACE_ID"; // Вставьте ваш Google Place ID сюда
  const apiKey = "AIzaSyA5GrnqRW2eiMC1aeAoU2zbN7r0a18dNTM"; // Вставьте ваш Google API ключ сюда

  //maps.googleapis.com/maps/api/place/findplacefromtext/json?input=Porto Cleaning&inputtype=textquery&fields=place_id&key=AIzaSyA5GrnqRW2eiMC1aeAoU2zbN7r0a18dNTM

  /*useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=reviews&key=${apiKey}`
        );

        if (!response.ok) {
          throw new Error("Ошибка при получении данных");
        }

        const data = await response.json();
        setReviews(data.result.reviews || []);
        console.log(data);
      } catch (error) {
        console.error("Ошибка при получении отзывов: ", error);
      }
    };

    fetchReviews();
  }, [placeId, apiKey]);*/

  return (
    <div className={styles.main}>
      <h2>Отзывы о нашем бизнесе</h2>
      {reviews.length > 0 ? (
        <ul>
          {reviews.map((review, index) => (
            <li key={index}></li>
          ))}
        </ul>
      ) : (
        <p>Отзывы не найдены.</p>
      )}
    </div>
  );
};

export default GoogleReviews;
