import { motion } from "framer-motion";
import styles from "./app-bottom.module.css";
import dog from "../../images/logo_2.png";
import { NavLink } from "react-router-dom";
import Setlink from "../setlink/setlink";
import { listMenu } from "../../utils/data";
import SlideWay from "../sladeway/slideway";

export function AppBottom() {
  return (
    <div className={styles.main}>
      <SlideWay></SlideWay>

      <div className={styles.part}>
        <Setlink tel={true}></Setlink>
      </div>
    </div>
  );
}
export default AppBottom;
//© 2024. Данный сайт носит исключительно информационный характер. Bсе предложения, указанные на сайте, не являются ни публичной офертой, ни рекламой.
