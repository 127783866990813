import styles from "./advice.module.css";
import { texta } from "../../utils/text";
import { useResize } from "../../utils/hook";
import CardInfo from "../cardinfo/cardinfo";

export const Advice = () => {
  return (
    <div className={styles.main}>
      <div className={styles.list}>
        {texta.map((item, index) => {
          return (
            <div key={index}>
              <CardInfo name={item}></CardInfo>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Advice;
