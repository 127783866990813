import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Tuser = {
  email: string;
  tocken: string;
  auth: boolean;
  id: string;
  name: string;
};

export const initialState: Tuser = {
  email: "",
  tocken: "",
  id: "",
  auth: false,
  name: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setuser: (state, action: PayloadAction<Tuser>) => {
      state.email = action.payload.email;
      state.tocken = action.payload.tocken;
      state.auth = true;
      state.id = action.payload.id;
      state.name = action.payload.name;
    },
    delluser: (state) => {
      state.email = "";
      state.tocken = "";
      state.auth = false;
      state.id = "";
      state.name = "";
    },
    setAuthChecked: (state) => {
      state.email = "";
      state.tocken = "";
      state.auth = false;
      state.id = "";
      state.name = "";
    },
  },
});

export default userSlice.reducer;
export const { setuser, delluser, setAuthChecked } = userSlice.actions;
