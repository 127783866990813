import React, { VFC, useState } from "react";
import styles from "./flat.module.css";
import imageflat from "../../images/imagemintwo.png";
import imageflatsfrom from "../../images/imagemin.png";
import { useResize } from "../../utils/hook";
import { useEffect } from "react";
import { motion, MotionValue, useTransform } from "framer-motion";

const textAnimation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom: number) => ({
    y: 0,
    opacity: 1,
    transition: { delay: 4, duration: 1 },
  }),
};

const Flat = () => {
  const wl = useResize().isScreenSm;
  const [images, setImages] = useState<string>(imageflat);

  useEffect(() => {
    if (wl) {
      setImages(imageflat);
    } else {
      setImages(imageflatsfrom);
    }
  }, [wl]);

  const backgraundMain = {
    backgroundImage: `url(${images})`,
  };

  return (
    <motion.div
      className={styles.main}
      style={backgraundMain}
      initial="hidden"
      whileInView="visible"
    >
      <motion.div className={styles.logob} variants={textAnimation}>
        <motion.div className={styles.logobox} variants={textAnimation}>
          <p className={styles.texttyping}>Мы заботимся о чистоте, чтобы</p>
          <p className={styles.texttyping}> Вы могли заботиться</p>
          <p className={styles.texttyping}> O важном.</p>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Flat;
