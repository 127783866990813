import styles from "./quest.module.css";
import { useEffect } from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { questions } from "../../utils/data";
import { NavLink } from "react-router-dom";
import { useResize } from "../../utils/hook";
import CardInfo from "../cardinfo/cardinfo";
import QuestOne from "../questone/questone";
import iconfon from "../../images/iconfon.jpg";
import Setlink from "../setlink/setlink";

export const Quest = () => {
  return (
    <div className={styles.main}>
      <h2>Часто задаваемые вопросы</h2>

      {questions.map((item, index) => {
        return (
          <div className={styles.box} key={index}>
            <QuestOne quest={item.quest} resp={item.res}></QuestOne>
          </div>
        );
      })}
      <h3>Не нашли ответа на свой вопрос?</h3>
      <h3>Напишите нам в соцсетях.</h3>
      <Setlink tel={false}></Setlink>
    </div>
  );
};

export default Quest;
