import { motion } from "framer-motion";
import styles from "./setlink.module.css";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import wa from "../../images/wa.png";
import waaction from "../../images/waactive.png";
import IconLink from "../iconlink/iconlink";
import insta from "../../images/instac.png";
import instac from "../../images/insta.png";
import tg from "../../images/telegram.png";
import tgaction from "../../images/telegramactive.png";
import tell from "../../images/tel.png";
import telaction from "../../images/telactive.png";
import Formcall from "../formcall/formcall";
import { phonenumber } from "../../utils/data";

type Tprops = {
  tel: boolean;
};
export const Setlink = ({ tel }: Tprops) => {
  const [isactivcall, setActivecall] = useState(false);

  useEffect(() => {
    if (isactivcall === true) {
      document.addEventListener("click", hendlerClick_2);
    }
  }, [isactivcall]);

  const hendlerClick_2 = (evt: any) => {
    if (isactivcall === true) {
      if (evt.target.className) {
        if (!evt.target.className.includes("call")) {
          if (!evt.target.className.includes("form")) {
            setActivecall(false);
            document.removeEventListener("click", hendlerClick_2);
          }
        }
      }
    }
  };

  return (
    <div className={styles.action}>
      {tel && (
        <div className={styles.part}>
          <motion.div
            whileHover={{ scale: 1.2, transition: { duration: 0.2 } }}
            className={styles.call}
            onClick={() => setActivecall(true)}
          >
            Перезвоните мне
          </motion.div>
        </div>
      )}

      <div className={styles.part}>
        <motion.div
          className={styles.icon}
          whileHover={{
            scale: 1.2,
            transition: { duration: 0.2 },
          }}
        >
          <NavLink to={"https://t.me/Russiancleaninginporto"} target="">
            <IconLink
              homeone={tg}
              hometwo={tgaction}
              rad={60}
              action={"false"}
            ></IconLink>
          </NavLink>
        </motion.div>
        <motion.div
          className={styles.icon}
          whileHover={{ scale: 1.2, transition: { duration: 0.2 } }}
        >
          <NavLink
            to={
              "https://wa.me/351911927602?text=Здравствуйте.%20Хочу%20заказать%20уборку%20перезвоните мне."
            }
          >
            <IconLink
              homeone={wa}
              hometwo={waaction}
              rad={60}
              action={"false"}
            ></IconLink>
          </NavLink>
        </motion.div>
        {!tel && (
          <motion.div
            className={styles.icon}
            whileHover={{ scale: 1.2, transition: { duration: 0.2 } }}
          >
            <NavLink
              to={"https://www.instagram.com/porto.cleaning"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconLink
                homeone={insta}
                hometwo={instac}
                rad={60}
                action={"false"}
              ></IconLink>
            </NavLink>
          </motion.div>
        )}
        {tel && (
          <motion.div
            className={styles.icon}
            whileHover={{ scale: 1.2, transition: { duration: 0.2 } }}
          >
            <NavLink to={`tel:${phonenumber}`}>
              <IconLink
                homeone={tell}
                hometwo={telaction}
                rad={60}
                action={"false"}
              ></IconLink>
            </NavLink>
          </motion.div>
        )}
      </div>
      {isactivcall && (
        <div className={styles.form}>
          <Formcall></Formcall>
        </div>
      )}
    </div>
  );
};
export default Setlink;
