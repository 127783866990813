import styles from "./logomain.module.css";
import { AnimatePresence, motion } from "framer-motion";
import { useInView } from "framer-motion";
import dog from "../../images/logo_1.png";
import LogoDraw from "../logodraw/logodraw";
import { delay } from "@reduxjs/toolkit/dist/utils";

const textAnimation = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: (custom: number) => ({
    x: 0,
    opacity: 1,
    transition: { delay: 4, duration: 0.3 },
  }),
};

const textAnimation2 = {
  hidden: {
    clipPath: "inset(50% 50% 50% 50% round 10px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.3,
    },
  },
  visible: (custom: number) => ({
    clipPath: "inset(0% 0% 0% 0% round 50px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.7,
      delayChildren: 0.3,
      staggerChildren: 0.05,
      delay: 2.5,
    },
  }),
};

export const LogoMain = () => {
  return (
    <div className={styles.main}>
      <LogoDraw></LogoDraw>

      <motion.div
        className={styles.preview}
        initial="hidden"
        whileInView="visible"
        variants={textAnimation2}
        whileHover={{ rotate: 360 }}
        transition={{
          duration: 1,
          repeat: 1,
          repeatDelay: 1,
          repeatType: "mirror",
          type: "tween",
        }}
      >
        <motion.img
          src={dog}
          alt="logo"
          animate={{ rotate: 360 }}
          transition={{
            duration: 2,
            repeat: 1,
            repeatDelay: 1,
            repeatType: "mirror",
            type: "tween",
          }}
        />
      </motion.div>
    </div>
  );
};
export default LogoMain;
