import styles from "./company.module.css";
import Contact from "../../components/contact/contact";
import Wecan from "../../components/wecan/wecan";

function Company() {
  return (
    <div className={styles.main}>
      <Wecan></Wecan>
      <Contact work={false}></Contact>
    </div>
  );
}

export default Company;
