import { NavLink } from "react-router-dom";
import styles from "./buttonord.module.css";
import icon from "../../images/logo_1.png";
import { AnimatePresence, motion } from "framer-motion";
import { useResize } from "../../utils/hook";
import { useEffect } from "react";

export function ButtonOrd() {
  const wl = useResize().isScreenLg;

  return (
    <motion.div
      className={styles.main}
      animate={{ rotate: [-360, 90, -90, 45, -45, 15, -15, 10, -10, 0] }}
      transition={{
        repeat: Infinity,
        repeatDelay: 5,
        duration: 2,
      }}
    >
      <NavLink className={styles.title} to="/order">
        <svg className={styles.circle} viewBox={"0 0 90 90"}>
          <path id="circle" d="M 0,50 a 50,50 0 1,1 0,1 z" />
          <text>
            <textPath xlinkHref="#circle">{`Заказать сейчас  ✮ ✮ ✮ ✮`}</textPath>
          </text>
        </svg>

        <img className={styles.icon} src={icon} alt="logo" />
      </NavLink>
    </motion.div>
  );
}
export default ButtonOrd;
