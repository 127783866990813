/*import { baseUrl } from "../utils/data";

export const sendMassege = async (massage: string): Promise<void> => {
  const url: string = "https:/telegram";
  //const url: string = `${baseUrl}sendMessage?chat_id=-100215897498&text=${massage}`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(massage),
  });

  if (!response.ok) {
    const error = await response.json();
    await Promise.reject(new Error(`fail ${error.description}`));
  }
  console.log("response", response);
};
//await Promise.reject(new Error('fail'));*/
export const sendMassege = async (massage: string): Promise<void> => {
  const url: string = `https://api.telegram.org/bot7402326848:AAENKvYfBNA7sHIx7F2RaTD8Cg6EQu4AYb0/sendMessage?chat_id=-1002158974798&text=${massage}`;
  //const url: string = `${baseUrl}sendMessage?chat_id=-100215897498&text=${massage}`;
  const response = await fetch(url);

  if (!response.ok) {
    const error = await response.json();
    await Promise.reject(new Error(`fail ${error.description}`));
  }
};
