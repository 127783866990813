import { useState } from "react";
import styles from "./maismenos.module.css";

type TMaismenos = {
  nameone: string;
  nametwo: string;
  namethree: string;
  func: React.Dispatch<React.SetStateAction<number>>;
  funcn: React.Dispatch<React.SetStateAction<string>>;
};

export function Maismenos(props: TMaismenos) {
  const setnum = props.func;
  const setnumn = props.funcn;

  const [col, setCol] = useState(1);
  const [name, setname] = useState(`${props.nameone}`);

  const namep = (col: number) => {
    if (col === 1) {
      setname(props.nameone);
      setnumn(props.nameone);
    } else {
      if (col > 1 && col < 5) {
        setname(props.nametwo);
        setnumn(props.nametwo);
      } else {
        setname(props.namethree);
        setnumn(props.namethree);
      }
    }
  };

  const onclickmais = () => {
    namep(col + 1);
    setCol(col + 1);
    setnum(col + 1);
  };

  const onclickmenos = () => {
    if (col > 1) {
      namep(col - 1);
      setCol(col - 1);
      setnum(col - 1);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.box} onClick={onclickmais}>
        +
      </div>
      <div className={styles.name}>{`${col} ${name}`}</div>
      <div className={styles.box} onClick={onclickmenos}>
        -
      </div>
    </div>
  );
}
export default Maismenos;
