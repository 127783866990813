import React, { VFC, useEffect, useState } from "react";
import styles from "./cardclean.module.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

type Tprops = {
  name: string;
  list: string[];
  image: string;
  link: string;
  dop?: boolean;
  ord?: boolean;
  price?: number;
  id?: string;
};

const Cardclean = (props: Tprops) => {
  const list = props.list;
  const [dop, setDop] = useState(false);
  const [dopf, setDopf] = useState({
    transform: `rotateY(0deg)`,
  });
  const [dopb, setDopb] = useState({
    transform: `rotateY(180deg)`,
  });
  useEffect(() => {
    if (dop) {
      setDopf({
        transform: `rotateY(180deg)`,
      });
      setDopb({
        transform: `rotateY(360deg)`,
      });
    } else {
      setDopf({
        transform: `rotateY(0deg)`,
      });
      setDopb({
        transform: `rotateY(180deg)`,
      });
    }
  }, [dop]);

  if (props.dop) {
    return (
      <motion.div
        className={styles.maind}
        whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
      >
        <div className={styles.front} style={dopf}>
          <img className={styles.image} src={props.image} alt={props.name} />
          <div className={styles.name}>{props.name}</div>
          {list.map((item, index) => {
            return (
              <div className={styles.item} key={index}>
                {item}
              </div>
            );
          })}
          <div className={styles.button} onClick={() => setDop(!dop)}>
            <div>{`Узнать больше >`}</div>
          </div>
        </div>

        <div className={styles.back} style={dopb}>
          <div className={styles.name}>{props.name}</div>
          <div className={styles.item}>{props.link}</div>
          <div className={styles.name}>{`цена: ${props.price} €`}</div>
          <div className={styles.button} onClick={() => setDop(!dop)}>
            <div>{`Назад >`}</div>
          </div>
        </div>
      </motion.div>
    );
  } else {
    if (props.ord) {
      return (
        <motion.div
          className={styles.main}
          whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
        >
          <img className={styles.image} src={props.image} alt={props.name} />
          <div className={styles.name}>{props.name}</div>
          {list.map((item, index) => {
            return (
              <div className={styles.item} key={index}>
                {item}
              </div>
            );
          })}
          <NavLink className={styles.button} to={`/personalorder/${props.id}`}>
            <div>{`Заказать >`}</div>
          </NavLink>
        </motion.div>
      );
    } else {
      return (
        <motion.div
          className={styles.main}
          whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
        >
          <img className={styles.image} src={props.image} alt={props.name} />
          <div className={styles.name}>{props.name}</div>
          {list.map((item, index) => {
            return (
              <div className={styles.item} key={index}>
                {item}
              </div>
            );
          })}

          <NavLink className={styles.button} to={props.link}>
            <div>{`Узнать больше >`}</div>
          </NavLink>
        </motion.div>
      );
    }
  }
};

export default Cardclean;
