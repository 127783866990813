import styles from "./logodraw.module.css";
import { motion } from "framer-motion";
import { useInView } from "framer-motion";
import dog from "../../images/logo_1.png";
const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i: number) => {
    const delay = 1 + i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

export const LogoDraw = () => {
  const textAnimationleft = {
    hidden: {
      x: 100,
      opacity: 0,
    },
    visible: (custom: number) => ({
      x: 0,
      opacity: 1,
      transition: { delay: 3.4, duration: 0.3 },
    }),
  };
  return (
    <div className={styles.main}>
      <div className={styles.box}>
        <motion.svg
          width="930"
          height="95"
          viewBox="0 0 1000 200"
          initial="hidden"
          animate="visible"
        >
          <motion.line
            x1="16"
            y1="230"
            x2="16"
            y2="28"
            stroke="rgb(8, 190, 181)"
            custom={3.5}
            variants={draw}
          />
          <motion.circle
            cx="100"
            cy="100"
            r="80"
            stroke="rgb(8, 190, 181)"
            variants={draw}
            custom={1}
          />

          <motion.circle
            cx="320"
            cy="130"
            r="110"
            stroke="rgb(8, 190, 181)"
            variants={draw}
            custom={1}
          />
          <motion.line
            x1="465"
            y1="230"
            x2="465"
            y2="30"
            stroke="rgb(8, 190, 181)"
            custom={3.5}
            variants={draw}
          />
          <motion.line
            x1="530"
            y1="180"
            x2="600"
            y2="230"
            stroke="rgb(8, 190, 181)"
            custom={3.5}
            variants={draw}
          />
          <motion.circle
            cx="550"
            cy="100"
            r="80"
            stroke="rgb(8, 190, 181)"
            variants={draw}
            custom={1}
          />
          <motion.line
            x1="700"
            y1="230"
            x2="700"
            y2="30"
            stroke="rgb(8, 190, 181)"
            custom={3.5}
            variants={draw}
          />
          <motion.line
            x1="650"
            y1="30"
            x2="750"
            y2="30"
            stroke="rgb(8, 190, 181)"
            custom={3.5}
            variants={draw}
          />
          <motion.circle
            cx="870"
            cy="130"
            r="110"
            stroke="rgb(8, 190, 181)"
            variants={draw}
            custom={1}
          />
          <motion.line
            x1="16"
            y1="240"
            x2="880"
            y2="240"
            stroke="rgb(8, 190, 181)"
            custom={3.5}
            variants={draw}
          />
        </motion.svg>
      </div>
      <motion.div
        className={styles.name}
        initial="hidden"
        whileInView="visible"
        variants={textAnimationleft}
      >
        CLEANING
      </motion.div>
    </div>
  );
};
export default LogoDraw;
