import { useEffect, useState } from "react";
import styles from "./runstring.module.css";

type TOrderypeprops = {
  strings: string[];
  delay: number;
};

type Tdefaults = {
  index: number;
  length: number;
  step: number;
};

const defaults: Tdefaults = {
  index: 0,
  length: 0,
  step: 1,
};

function RunString(props: TOrderypeprops) {
  const strings = props.strings;
  const delay = props.delay;

  const [state, setState] = useState<Tdefaults | null>(null);

  useEffect(() => {
    setState(() => ({ ...defaults }));
  }, [strings]);

  useEffect(() => {
    let delaym = delay;
    if (state !== null) {
      if (state.length === strings[state.index].length) {
        delaym = delay * 40;
      }
      if (state.length === 0) {
        delaym = delay * 10;
      }
    }

    const timeoutId = setTimeout(setState, delaym, ({ ...state }) => {
      state.length += state.step;
      if (state.length === strings[state.index].length) {
        state.step = -1;
      } else if (state.length === 0) {
        state.step = 1;
        state.index = (state.index + 1) % strings.length;
      }
      return state;
    });

    return () => clearTimeout(timeoutId);
  });

  if (state === null) {
    return null;
  }
  return (
    <div className={styles.main}>
      <div className={styles.text}>
        {strings[state.index].slice(0, state.length)}
      </div>
      <div className={styles.but}></div>
    </div>
  );
}

export default RunString;
