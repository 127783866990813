import styles from "./main.module.css";
import Video from "../../components/video/video";
import Flat from "../../components/flat/flat";
import { useResize } from "../../utils/hook";
import SlideWay from "../../components/sladeway/slideway";
import Wecan from "../../components/wecan/wecan";
import Contact from "../../components/contact/contact";
import None from "../../components/none/none";
import { list, listdop } from "../../utils/data";
import Cleaningnew from "../../components/cleaningnew/cleaningnew";
import { Helmet } from "react-helmet-async";
import Quest from "../../components/quest/quest";
import iconfon from "../../images/iconfon.jpg";
import { useEffect, useState } from "react";
import AppHeader from "../../components/app-header/app-header";

import { motion, useScroll, useTransform } from "framer-motion";

function MainPage() {
  const ww = useResize();
  const [wlh, setWLH] = useState(ww.hight);
  const [wlw, setWLW] = useState(ww.width);

  const wl = ww.isScreenXl;
  const scrollY = useScroll().scrollY;
  const offsetY = [0, wlh];
  const mm = [0, wlh];
  let marginTop = wlh + 100;
  //let marginTop = useTransform(scrollY, mm, mm);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setWLH(ww.hight);
    setWLW(ww.width);
  }, [ww.hight, ww.width]);

  return (
    <div className={styles.main}>
      <Helmet>
        <title>
          Русскоговорящий клининг в Португалии Порто и большой Порто
        </title>
        <meta name="description" content="Уборка домов и квартир в Порто" />
        <link rel="canonical" href="https://www.portocleaning.com/" />
      </Helmet>
      <motion.div className={styles.header}>
        <AppHeader
          wl={wl}
          wlh={wlh}
          wlw={wlw}
          scrollY={scrollY}
          offsetY={offsetY}
        ></AppHeader>
      </motion.div>
      {wl && (
        <motion.div className={styles.section_121}>
          <Video></Video>
        </motion.div>
      )}

      {!wl && (
        <motion.div className={styles.section_121}>
          <Flat></Flat>
        </motion.div>
      )}

      <motion.div className={styles.section_1} style={{ marginTop }}>
        <SlideWay></SlideWay>
      </motion.div>
      <div
        className={styles.section_12}
        style={{ backgroundImage: `url(${iconfon})` }}
      >
        <Wecan></Wecan>
        <None hh={100}></None>
      </div>
      <div className={styles.section_1}>
        <SlideWay></SlideWay>
      </div>
      <div
        className={styles.section_12}
        style={{ backgroundImage: `url(${iconfon})` }}
      >
        <Cleaningnew name="Уборка домов и квартир" list={list}></Cleaningnew>
        <None hh={100}></None>
      </div>
      <div className={styles.section_1}>
        <SlideWay></SlideWay>
      </div>
      <div
        className={styles.section_12}
        style={{ backgroundImage: `url(${iconfon})` }}
      >
        <Cleaningnew name="Дополнительные услуги" list={listdop}></Cleaningnew>
        <None hh={200}></None>
      </div>
      <div className={styles.section_1}>
        <SlideWay></SlideWay>
      </div>
      <div
        className={styles.section_12}
        style={{ backgroundImage: `url(${iconfon})` }}
      >
        <Contact work={false}></Contact>
        <None hh={200}></None>
      </div>
      <div
        className={styles.section_1}
        style={{ backgroundImage: `url(${iconfon})` }}
      >
        <SlideWay></SlideWay>

        <Quest></Quest>
      </div>
    </div>
  );
}

export default MainPage;
//<Reclama></Reclama>
/*<div className={styles.section_1}>
<SlideWay></SlideWay>
</div>
<div className={styles.section_12}>
<GoogleReviews></GoogleReviews>
</div>*/

/*
 <div className={styles.section_12}>
        <Flat></Flat>
      </div>
      {wl && <div className={styles.examplecontainer2}></div>}
      {wl && (
        <div className={styles.section_1}>
          <SlideWay></SlideWay>
        </div>
      )}
      {wl && (
        <div className={styles.section_12}>
          <Video></Video>
        </div>
      )}
      <div className={styles.section_1}>
        <SlideWay></SlideWay>
      </div>
      <div className={styles.section_12}>
        <Wecan></Wecan>
        <None hh={100}></None>
      </div>

      <div className={styles.section_1}>
        <SlideWay></SlideWay>
      </div>
      <div className={styles.section_12}>
        <Cleaningnew name="Уборка домов и квартир" list={list}></Cleaningnew>
        <None hh={100}></None>
      </div>

      <div className={styles.section_1}>
        <SlideWay></SlideWay>
      </div>
      <div className={styles.section_12}>
        <Cleaningnew name="Дополнительные услуги" list={listdop}></Cleaningnew>
        <None hh={200}></None>
      </div>
      <div className={styles.section_1}>
        <SlideWay></SlideWay>
        <Contact></Contact>

        <SlideWay></SlideWay>

        <Quest></Quest>
        <Reclama></Reclama>
      </div>
*/
/*{wl && (
  <div className={styles.section_12}>
    <SlideWay></SlideWay>
    <Video></Video>
  </div>
)}

<div className={styles.section_12}>
  <SlideWay></SlideWay>
  <Wecan></Wecan>
  <None hh={100}></None>
</div>

<div className={styles.section_12}>
  <SlideWay></SlideWay>
  <Cleaningnew name="Уборка домов и квартир" list={list}></Cleaningnew>
  <None hh={100}></None>
</div>

<div className={styles.section_12}>
  <SlideWay></SlideWay>
  <Cleaningnew name="Дополнительные услуги" list={listdop}></Cleaningnew>
  <None hh={200}></None>
</div>
<div className={styles.section_1}>
  <SlideWay></SlideWay>
  <Contact></Contact>

  <SlideWay></SlideWay>

  <Quest></Quest>
</div>*/

/*<motion.div className={styles.header}>
<AppHeader
  wl={wl}
  wlh={wlh}
  wlw={wlw}
  scrollY={scrollY}
  offsetY={offsetY}
></AppHeader>
</motion.div>*/
/*<motion.div className={styles.section_1} style={{ marginTop }}>
{wl && (
  <div className={styles.section_12} style={{ paddingTop: 0 }}>
    <Video></Video>
  </div>
)}

{!wl && (
  <div className={styles.section_12} style={{ paddingTop: 0 }}>
    <Flat></Flat>
  </div>
)}
</motion.div>*/
