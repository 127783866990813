import styles from "./app.module.css";
import { HelmetProvider } from "react-helmet-async";
import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import MainPage from "../../pages/main/main";
import Coast from "../../pages/coast/coast";
import AppHeader from "../app-header/app-header";
import Regularclean from "../../pages/regularclean/regularclean";
import Afterrepairclean from "../../pages/afterrepairclean/afterrepairclean";
import Deepclean from "../../pages/deepclean/deepclean";
import Clean from "../../pages/clean/clean";
import AppBottom from "../app-bottom/app-bottom";
import Company from "../../pages/company/company";
import Order from "../../pages/orderpage/orderpage";
import Adviceone from "../adviceone/adviceone";
import AdvicePage from "../../pages/advicepage/advicepage";
import Trabalha from "../../pages/trabalha/trabalha";
import TimeClean from "../../pages/timeclean/timeclean";
import OrderPage from "../../pages/orderpage/orderpage";
import OrderPagePersonal from "../../pages/orderpagepersonal/orderpagepersonal";
import GoogleReviews from "../clients/clients";
import iconfon from "../../images/iconfon.jpg";
import { useViewportSize } from "@mantine/hooks";
import { motion, useScroll, useTransform } from "framer-motion";
import { useResize } from "../../utils/hook";
import AppHeaderSmall from "../app-headersmall/app-headersmall";

function App() {
  useEffect(() => {
    document.title = "PortoCLEANING";
  }, []);
  const pathname = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <HelmetProvider>
      <div className={styles.app}>
        <AppHeaderSmall></AppHeaderSmall>
        <Routes>
          <Route path="/" element={<MainPage />} />
        </Routes>

        <div
          className={styles.main}
          style={{ backgroundImage: `url(${iconfon})` }}
        >
          <Routes>
            <Route path="/price" element={<Coast />} />
            <Route path="/company" element={<Company />} />
            <Route path="/reviews" element={<GoogleReviews />} />
            <Route path="/order" element={<OrderPage />} />
            <Route
              path="/personalorder/:clean"
              element={<OrderPagePersonal />}
            />
            <Route path="/order/:sale" element={<OrderPage />} />
            <Route path="/clean" element={<Clean />} />
            <Route path="/baseclean" element={<Regularclean />} />
            <Route path="/deepclean" element={<Deepclean />} />
            <Route path="/afterrepairclean" element={<Afterrepairclean />} />
            <Route path="/timeclean" element={<TimeClean />} />
            <Route path="/advice/:name" element={<Adviceone />} />
            <Route path="/advice" element={<AdvicePage />} />
            <Route path="/work" element={<Trabalha />} />
          </Routes>
        </div>
        <div className={styles.section_pod}>
          <AppBottom></AppBottom>
        </div>
      </div>
    </HelmetProvider>
  );
}

export default App;
//<Route path="/profile" element={<OnlyAuth component={<Profile />} />} />
//<Route path="/reg" element={<Formcall />} />
/*<div className={styles.header}>
          <AppHeader></AppHeader>
        </div>*/
/*<div className={styles.header}>
        <AppHeader></AppHeader>
      </div>*/
/* return (
        <HelmetProvider>
          <div className={styles.app}>
            <div className={styles.header}>
              <AppHeader
                wlh={wlh}
                wlw={wlw}
                wl={wl}
                scrollY={scrollY}
                offsetY={offsetY}
              ></AppHeader>
            </div>
            <motion.div
              className={styles.main}
              style={{ marginTop, backgroundImage: `url(${iconfon})` }}
            >
              <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/price" element={<Coast />} />
                <Route path="/company" element={<Company />} />
                <Route path="/reviews" element={<GoogleReviews />} />
                <Route path="/order" element={<OrderPage />} />
                <Route
                  path="/personalorder/:clean"
                  element={<OrderPagePersonal />}
                />
                <Route path="/order/:sale" element={<OrderPage />} />
                <Route path="/clean" element={<Clean />} />
                <Route path="/baseclean" element={<Regularclean />} />
                <Route path="/deepclean" element={<Deepclean />} />
                <Route path="/afterrepairclean" element={<Afterrepairclean />} />
                <Route path="/timeclean" element={<TimeClean />} />
                <Route path="/advice/:name" element={<Adviceone />} />
                <Route path="/advice" element={<AdvicePage />} />
                <Route path="/work" element={<Trabalha />} />
              </Routes>
            </motion.div>
            <div className={styles.section_pod}>
              <AppBottom></AppBottom>
            </div>
          </div>
        </HelmetProvider>
      );*/
/*function App() {
        useEffect(() => {
          document.title = "PortoCLEANING";
        }, []);
        const ww = useResize();
        const [wlh, setWLH] = useState(ww.hight);
        const [wlw, setWLW] = useState(ww.width);
      
        const wl = ww.isScreenSm;
      
        const offsetY = [0, wlh - 80];
      
        useEffect(() => {
          window.scrollTo(0, 0);
        }, []);
      
        useEffect(() => {
          setWLH(ww.hight);
          setWLW(ww.width);
        }, [ww.hight, ww.width]);
      
        const scrollY = useScroll().scrollY;
        ///let marginTop = useTransform(scrollY, offsetY, [0, wlh]);
        let marginTop = wlh + 20;
        return (
          <HelmetProvider>
            <div className={styles.app}>
              <div className={styles.header}>
                <AppHeader
                  wlh={wlh}
                  wlw={wlw}
                  wl={wl}
                  scrollY={scrollY}
                  offsetY={offsetY}
                ></AppHeader>
              </div>
              <motion.div
                className={styles.main}
                style={{ marginTop, backgroundImage: `url(${iconfon})` }}
              >
                <Routes>
                  <Route path="/" element={<MainPage />} />
                  <Route path="/price" element={<Coast />} />
                  <Route path="/company" element={<Company />} />
                  <Route path="/reviews" element={<GoogleReviews />} />
                  <Route path="/order" element={<OrderPage />} />
                  <Route
                    path="/personalorder/:clean"
                    element={<OrderPagePersonal />}
                  />
                  <Route path="/order/:sale" element={<OrderPage />} />
                  <Route path="/clean" element={<Clean />} />
                  <Route path="/baseclean" element={<Regularclean />} />
                  <Route path="/deepclean" element={<Deepclean />} />
                  <Route path="/afterrepairclean" element={<Afterrepairclean />} />
                  <Route path="/timeclean" element={<TimeClean />} />
                  <Route path="/advice/:name" element={<Adviceone />} />
                  <Route path="/advice" element={<AdvicePage />} />
                  <Route path="/work" element={<Trabalha />} />
                </Routes>
              </motion.div>
              <div className={styles.section_pod}>
                <AppBottom></AppBottom>
              </div>
            </div>
          </HelmetProvider>
        );
      }
      
      export default App;*/
