import { createSlice, PayloadAction} from "@reduxjs/toolkit";

    type Tscreen = {
    width: number,
    isScreenSm:boolean,
    isScreenMd: boolean,
    isScreenLg: boolean,
    isScreenXl: boolean,
    isScreenXxl: boolean,
  }



export const initialState: Tscreen = {
        width: 1400,
        isScreenSm: false,
        isScreenMd: false,
        isScreenLg: false,
        isScreenXl: false,
        isScreenXxl: true,
  };

   
  export const screenSlice = createSlice({
    name: "screen",
    initialState: initialState,
    reducers: {
      setscreen: (state, action:PayloadAction<Tscreen>) => {
        state = action.payload;
      }
    }
  });

  export default screenSlice.reducer;
  export const { setscreen } = screenSlice.actions;
   