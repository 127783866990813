import React, { useState } from "react";
import styles from "./compoundnew.module.css";
import kitchen from "../../images/kitchen.png";
import bedroom from "../../images/bedroom.png";
import toilet from "../../images/toilet.png";
import coridor from "../../images/coridor.png";
import { motion, AnimatePresence } from "framer-motion";
import { TlistLoc } from "../../utils/data";
import { Typeloc } from "../../utils/data";
import OrderLink from "../orderlink/orderlink";
import SimpleCH from "../slaiderch/slaiderch";

type Tprops = {
  listloc: TlistLoc[];
};

const Compoundnew = ({ listloc }: Tprops) => {
  const [background, setBackgraund] = useState({
    backgroundImage: `url(${bedroom})`,
  });

  const [listWork, setlistWork] = useState(listloc[0].work);
  const [room, setRoom] = useState(listloc[0].ref);

  const onClick = (loc: Typeloc) => {
    if (loc.ref === "Room") {
      setBackgraund({ backgroundImage: `url(${bedroom})` });
    }
    if (loc.ref === "Kitchen") {
      setBackgraund({ backgroundImage: `url(${kitchen})` });
    }
    if (loc.ref === "Coredor") {
      setBackgraund({ backgroundImage: `url(${coridor})` });
    }
    if (loc.ref === "wc") {
      setBackgraund({ backgroundImage: `url(${toilet})` });
    }
    setlistWork(loc.work);
    setRoom(loc.ref);
  };

  return (
    <div className={styles.main}>
      <div className={styles.menu}>
        {listloc.map((item) => {
          if (item.ref === room) {
            return (
              <motion.button
                className={`${styles.button} ${styles.buttonaction}`}
                onClick={() => onClick(item)}
                whileHover={{ scale: 1.05 }}
                key={item.name}
              >
                {item.name}
              </motion.button>
            );
          } else {
            return (
              <motion.button
                className={styles.button}
                onClick={() => onClick(item)}
                whileHover={{ scale: 1.05 }}
                key={item.name}
              >
                {item.name}
              </motion.button>
            );
          }
        })}
      </div>
      <div className={styles.userBox}>
        <AnimatePresence>
          <motion.div
            style={background}
            className={styles.fon}
            initial={{ x: -2000, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 2000, opacity: 0 }}
            transition={{
              delay: 0.2,
              duration: 1.5,
            }}
            key={room}
          >
            <div className={styles.stringtext}>
              <SimpleCH list={listWork} slidesToShow={1}></SimpleCH>
            </div>
            <div className={styles.link}>
              <OrderLink
                size={150}
                name="Сколько это стоит"
                link="/price"
              ></OrderLink>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Compoundnew;
