import styles from "./slideway.module.css";
import sly from "../../images/slide.png";
import { textr } from "../../utils/data";
import { NavLink } from "react-router-dom";

function SlideWay() {
  const backgraundMain = {
    backgroundImage: `url(${sly})`,
  };
  return <div className={styles.main} style={backgraundMain}></div>;
}

export default SlideWay;
