import styles from "./questone.module.css";
import { useEffect } from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { questions } from "../../utils/data";
import { NavLink } from "react-router-dom";
import { useResize } from "../../utils/hook";
import icon from "../../images/arrowdown.png";
import iconx from "../../images/arrowup.png";

type Tprops = {
  quest: string;
  resp: string;
};

export const QuestOne = ({ quest, resp }: Tprops) => {
  const [open, setopen] = useState(false);
  return (
    <motion.div className={styles.main} initial={false}>
      <div
        className={styles.box}
        onClick={() => {
          setopen(!open);
        }}
      >
        <motion.img
          src={icon}
          animate={{ rotate: open ? 180 : 0 }}
          transition={{ type: "spring", stiffness: 300, damping: 20 }}
          alt="menu"
          className={styles.icon}
        ></motion.img>
        <div className={styles.text}>
          <p>{quest}</p>
          <AnimatePresence>
            {open && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                exit={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                transition={{
                  delay: 0.1,
                  duration: 0.6,
                }}
                className={styles.menu}
              >
                {open && (
                  <motion.div style={{ padding: 20 }}>{resp}</motion.div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  );
};

export default QuestOne;
/*<AnimatePresence>
  {open && (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      exit={{ height: 0, opacity: 0 }}
      animate={{ height: "auto", opacity: 1 }}
      transition={{
        delay: 0.1,
        duration: 0.6,
      }}
      className={styles.menu}
    >
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 0.3,
            duration: 0.6,
          }}
          style={{ padding: 20 }}
        >
          {resp}
        </motion.div>
      )}
    </motion.div>
  )}
</AnimatePresence>;*/
