import React, { VFC, useEffect, useState } from "react";
import styles from "./cardinfo.module.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { Ttext } from "../../utils/text";

type Tprops = {
  name: Ttext;
};

const CardInfo = ({ name }: Tprops) => {
  return (
    <motion.div
      className={styles.main}
      whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
    >
      <img className={styles.image} src={name.content[0].image} alt={"image"} />

      <NavLink className={styles.button} to={`/advice/${name.id}`}>
        <div>{`Читать >`}</div>
      </NavLink>

      <div className={styles.name}>{name.name}</div>
    </motion.div>
  );
};

export default CardInfo;
